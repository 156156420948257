import styled from "styled-components";

import { getMissingFields } from "api/services/dealService";
import DealDetailsModalTrigger from "components/DealDetailsModalTrigger";
import { PencilIcon, WarningIcon } from "components/ui/Icons";
import { safeFormat } from "utils/common";
import { Link } from "react-router-dom";
import { last } from "lodash";

const Card = styled.div`
  width: 260px;
  font-size: 12px;
  position: relative;
  padding: 8px;
  background-color: ${props => props.theme.color.closer0};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12);
  border: 1px solid black;
  border-radius: 2px;

  ${props =>
    props.isDarkerShade &&
    `
    background-color: black;
    border-color: ${props.theme.color.closer0};
    `};
`;

const IssuerName = styled.div`
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #000000;
  font-weight: 600;
  color: #879ba6;
`;

const SmallCard = styled.div`
  width: max-content;
  background-color: #2f3135;
  color: ${props => props.theme.color.closer2};
  padding: 4px;
  border-radius: 2px;
  font-weight: 600;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid
    ${props => (props?.isHighlighted ? "#ff8e39" : "transparent")};
`;

const BankCards = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const OrangeText = styled.div`
  color: #ff8e39;
  font-weight: 600;
`;

const PrimaryText = styled.div`
  color: ${props => props.theme.color.primary};
  font-weight: 600;
`;

const BorderedName = styled.div`
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid
    ${props => (props.isDarkerShade ? props.theme.color.closer0 : "#000000")};
`;

const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  fill: ${props => props.theme.color.closest};
  height: 12px;
  :hover {
    opacity: 0.5;
  }
`;

const DealRecordCard = ({ schema = {}, records = [] }) => {
  const tapValue = last(records)?.extractedFields?.["Tap Type"]?.value;
  const isTap = !!tapValue && tapValue !== "Not a tap";
  const partnerBankNames =
    last(records)?.extractedFields?.["Partner bank"]?.value || [];
  const senderBankNames =
    last(records)?.extractedFields?.["Sender banks"]?.value;

  return (
    <Card
      key={last(records)?.extractedFields?.name?.value}
      isDarkerShade={!partnerBankNames.length}
    >
      <IssuerName>
        {last(records)?.extractedFields?.["Issuer Name"]?.value}
      </IssuerName>
      {records?.map((record, i) => (
        <div style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
          <BorderedName isDarkerShade={!partnerBankNames.length}>
            {record?.extractedFields?.name?.value}
          </BorderedName>
          <Link to={`/deals/${record?._id}`}>
            <StyledPencilIcon />
          </Link>
        </div>
      ))}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BankCards>
          {partnerBankNames.map((bankName, i) => (
            <SmallCard
              isHighlighted={senderBankNames?.includes(bankName)}
              key={i}
            >
              {bankName}
            </SmallCard>
          ))}
        </BankCards>
      </div>
      <div
        style={{
          marginTop: "8px",
          backgroundColor: "#d4a60044",
          padding: "4px",
          gap: "4px",
          alignItems: "center",
          width: "max-content",
          display: getMissingFields(last(records), schema)?.length
            ? "grid"
            : "none",
          gridAutoFlow: "column",
          justifyContent: "start",
        }}
      >
        <WarningIcon
          style={{
            height: "12px",
            width: "12px",
            fill: "#d4a600",
          }}
        />
        <div
          style={{
            maxWidth: "165px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {getMissingFields(last(records), schema)?.join(", ")}
        </div>
      </div>
      <div
        style={{
          marginTop: "8px",
          fontSize: "10px",
          color: "#888",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {safeFormat(new Date(last(records)?.updatedAt), "dd/MM/yyyy HH:mm:ss")}
        {isTap && <OrangeText>TAP</OrangeText>}
        {records?.length > 1 && <PrimaryText>Multi-Tranche</PrimaryText>}
      </div>
    </Card>
  );
};

export default DealRecordCard;
