import styled from "styled-components";

const BlueButton = styled.input`
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.furthest};
  border-radius: 4px;
  font-weight: 600;
  padding: 10px 25px;
  min-width: 140px;
  max-width: 250px;
  max-height: 50px;
  width: max-content;
  border: 2px solid ${props => props.theme.color.primary};
  font-family: "Inter";

  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.2};

  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.primary};
  }
`;

const SolidBlueButton = styled.input`
  cursor: pointer;
  color: ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.primary};
  border-radius: 40px;
  padding: 10px 25px;
  min-width: 140px;
  max-width: 250px;
  max-height: 50px;
  width: max-content;
  border: 2px solid ${props => props.theme.color.primary};

  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.2};

  :hover {
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.furthest};
  }
`;

const Button = ({
  value,
  onClick,
  className,
  isDisabled,
  style,
  dataTutorialId,
  isSolid = false,
}) =>
  isSolid ? (
    <SolidBlueButton
      data-tutorial-id={dataTutorialId}
      isDisabled={isDisabled}
      className={className}
      onClick={onClick}
      type="submit"
      value={value}
      style={style}
    />
  ) : (
    <BlueButton
      data-tutorial-id={dataTutorialId}
      isDisabled={isDisabled}
      className={className}
      onClick={onClick}
      type="submit"
      value={value}
      style={style}
    />
  );

export default Button;
