import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  getDealsSearch,
  getSummaryStats,
  patchDeal,
} from "api/services/dealService";
import RecordsCards from "components/RecordsCards";
import SideNav from "components/SideNav";
import { STATS, formatStatValue } from "pages";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 10px;
`;

const StatsContainer = styled.div`
  height: max-content;

  display: grid;
  grid-auto-flow: column;
  padding-top: 10px;
`;

const StatsAndSearch = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr auto auto auto auto;
  align-items: center;
  padding-right: 8px;
`;

const StyledInput = styled.input`
  color-scheme: dark;
  border: 1px solid transparent;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
  outline: none;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #181a1f;
  font-family: "Inter";
`;

const Stat = styled.div`
  display: grid;
  gap: 4px;
`;

const StatTitle = styled.div`
  font-size: 11px;
  color: ${props => props.theme.color.closer2};
`;

const StatValue = styled.div`
  font-size: 17px;
  color: ${props => props.theme.color.closest};
`;

const StyledSelect = styled.select`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledButton = styled.button`
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  color: ${props => props.theme.color.closer2};
  background-color: transparent;
  cursor: pointer;
  font-size: 11px;

  :hover {
    background-color: ${props => props.theme.color.closer2};
    color: ${props => props.theme.color.furthest};
  }
`;

const getStats = records => {
  const pbCount = records?.filter(
    record => record?.extractedFields?.["Partner bank"]?.value?.length > 0
  ).length;

  return [
    {
      label: "Total Deals",
      value: records?.length,
    },
    {
      label: "PB Deals",
      value: pbCount,
    },
    {
      label: "Not PB Deals",
      value: records?.length - pbCount,
    },
    {
      label: "Coverage",
      value: "80%",
    },
    {
      label: "Tranches",
      value: 2,
    },
    {
      label: "Total Issue Size",
      value: "5mm",
    },
    {
      label: "Total Book Size",
      value: "15mm",
    },
    {
      label: "Subscription Ratio",
      value: "3x",
    },
  ];
};

const today = new Date();

const ArchivePage = () => {
  const [allRecords, setAllRecords] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchApiFilter, setSearchApiFilter] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState(today?.toISOString()?.split("T")?.[0]);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    doPopulateDeals();

    const intervalId = setInterval(() => {
      doPopulateDeals();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [searchApiFilter, startTime, endTime]);

  useEffect(() => {
    doPopulateStats();

    const intervalId = setInterval(() => {
      doPopulateStats();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [searchApiFilter, startTime, endTime]);

  const doPopulateStats = async () => {
    const { data } = await getSummaryStats({
      search: searchApiFilter,
      statuses: ["Priced"],
      startTime,
      endTime,
    });

    const newStats = [];
    STATS.forEach(statName => {
      newStats.push({
        label: statName,
        value: data?.[statName],
      });
    });

    setStats(newStats);
  };

  const doPopulateDeals = async () => {
    const { data: acrchivedDeals, error } = await getDealsSearch({
      search: searchApiFilter,
      statuses: ["Priced"],
      startTime,
      endTime,
    });

    if (error?.type === "Unauthorised") {
      window.location = "/login";
      return;
    }

    setAllRecords(acrchivedDeals);
  };

  const doPatchRecord = async (id, body) => {
    const { data: updatedRecord } = await patchDeal(id, {}, body);
    const newRecords = allRecords.map(record =>
      record._id === id ? updatedRecord : record
    );
    setAllRecords(newRecords);
  };

  const archivedRecords = allRecords;

  return (
    <Container>
      <div style={{ width: "60px", gridRow: "span 2" }} />
      <SideNav />

      <StatsAndSearch>
        <StatsContainer>
          {stats.map(stat => (
            <Stat>
              <StatTitle>{stat.label}</StatTitle>
              <StatValue>{formatStatValue(stat?.label, stat.value)}</StatValue>
            </Stat>
          ))}
        </StatsContainer>
        <StyledInput
          value={startTime}
          onChange={e => setStartTime(e.target.value)}
          type="date"
        />
        <StyledInput
          value={endTime}
          onChange={e => setEndTime(e.target.value)}
          type="date"
        />
        <StyledInput
          placeholder="Search"
          value={searchInputValue}
          onChange={e => setSearchInputValue(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              setSearchApiFilter(searchInputValue);
            }
          }}
        />
        <StyledButton onClick={() => setSearchApiFilter(searchInputValue)}>
          Search
        </StyledButton>
      </StatsAndSearch>

      <RecordsCards
        records={archivedRecords}
        onClickSaveRecord={(id, newFields) => doPatchRecord(id, newFields)}
      />
    </Container>
  );
};

export default ArchivePage;
