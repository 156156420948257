import { useState, useEffect } from "react";
import styled from "styled-components";

import TextInput from "components/inputs/TextInput";
import { getPlatformLoginToken } from "api/services/authenticationService";
import Button from "components/ui/Button";
import { Error } from "@material-ui/icons";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import { BoltzhubLogo, LiquidnetLogo, WaveLogo } from "components/ui/Icons";

const Container = styled.div`
  padding-top: 200px;
  display: grid;
  grid-template-columns: 1fr 500px 1fr;
  font-size: 14px;
`;

const Gap = styled.div`
  height: 10px;
`;

const Logo = styled.img`
  margin: 0;
  width: 50px;
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5px;
`;

const ForgotPasswordLinkDiv = styled.div`
  text-decoration: underline;
  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  padding: 15px;
  color: ${props => props.theme.color.error};
  width: 100%;
  justify-content: center;
`;

const EmailMesssage = styled.div`
  display: flex;
  padding: 15px;
  color: ${props => props.theme.color.primary};
  width: max-content;
  width: 100%;
  justify-content: center;
`;

const Form = styled.form`
  grid-column: 2;
  padding: 35px 50px 35px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 4px;

  position: relative;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const CheckSpamMsg = styled.div`
  max-width: 400px;
  margin-top: 10px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  ${props => !props.isVisible && "pointer-events: none;"};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${props => props.theme.color.feature};
  padding: 5px;
  border-radius: 5px;
  width: max-content;
`;

const Input = styled.input`
  background-color: transparent;
  color: white;
  color-scheme: dark;
  padding: 2px 0;

  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  font-size: 11px;
  color-scheme: dark;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const PoweredText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  bottom: 4px;
  right: 4px;
  opacity: 0.6;
  font-size: 10px;
`;

const LoginPage = () => {
  const [inputUserName, setInputUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [emailMesssage, setEmailMessage] = useState("");
  const [notificationMsg, setNotificationMsg] = useState(
    "Your login may be in your spam."
  );

  const isDarkTheme = localStorage.getItem("theme") === "dark";

  useEffect(() => {
    setError(null);
    setEmailMessage("");
    setIsLoading(false);
    setPassword("");
  }, [forgotPasswordMode]);

  const logInAndRedirect = async e => {
    setIsLoading(true);
    e.preventDefault();
    const { data, error } = await getPlatformLoginToken(
      inputUserName,
      password
    );

    setIsLoading(false);
    if (error) {
      setError(error);
      return;
    }

    if (data?.isTwoFactorRequired) {
      window.location = `/one-time-code?userId=${inputUserName}`;
      return;
    }

    setLoginTokenInClientStorage(data.accessToken);
    window.location = "/";
  };

  const doResetPassword = async e => {
    setIsLoading(true);
    // e.preventDefault();
    // const { error } = await resetPassword(inputUserName);
    // if (error) {
    //   setError(error);
    //   return;
    // }
    // setNotificationMsg(
    //   "A new password has been sent to your email. Check your spam."
    // );
    // setForgotPasswordMode(false);
    setIsLoading(false);
  };

  const switchMode = () => {
    setForgotPasswordMode(!forgotPasswordMode);
  };

  if (forgotPasswordMode) {
    return (
      <Container>
        <Form onSubmit={doResetPassword}>
          <Logo
            src={
              isDarkTheme ? "/images/logo-white.png" : "/images/logo-black.png"
            }
          />
          <Gap height="18px" />
          <TextInput
            title="Username"
            value={inputUserName}
            onNewInput={newUsername => setInputUserName(newUsername)}
            isRequired
          />
          <Gap />
          <ErrorMessage>{error?.message}</ErrorMessage>
          <EmailMesssage>{emailMesssage}</EmailMesssage>
          <ButtonContainer>
            <Button
              type="submit"
              value={isLoading ? "Loading..." : "Reset Password"}
            />
          </ButtonContainer>
        </Form>
      </Container>
    );
  }

  return (
    <Container>
      <Form onSubmit={logInAndRedirect}>
        <div
          style={{
            display: "grid",
            gap: "12px",
          }}
        >
          <WaveLogo height="20px" />
          <LiquidnetLogo style={{ fill: "white", height: "20px" }} />
        </div>
        <Gap />
        <TextInput
          title="Username"
          value={inputUserName}
          onNewInput={newUsername => setInputUserName(newUsername)}
          isRequired
        />
        {/* <ForgotPasswordContainer>
          <ForgotPasswordLinkDiv
            onClick={() => {
              switchMode();
            }}
          >
            forgot password
          </ForgotPasswordLinkDiv>
        </ForgotPasswordContainer> */}
        <Gap style={{ height: "30px" }} />
        <TextInput
          title="Password"
          value={password}
          type="password"
          onNewInput={newPassword => setPassword(newPassword)}
          isRequired
        />
        <ErrorMessage>{error?.message}</ErrorMessage>
        <ButtonContainer>
          <Button type="submit" value={isLoading ? "Loading..." : "Login"} />
        </ButtonContainer>
        {/* <CheckSpamMsg isVisible>
          <Error />
          {notificationMsg}
        </CheckSpamMsg> */}
        <PoweredText>
          Powered by Boltzbit AI
          <BoltzhubLogo />
        </PoweredText>
      </Form>
    </Container>
  );
};

export default LoginPage;
