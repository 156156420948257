import styled from "styled-components";
import { useState, useEffect } from "react";

import {
  deleteParsedResults,
  getConfig,
  getExtractionSchema,
  getParsedResult,
  patchParsedResult,
  postAdhocIngestions,
  postParsedResultsCreateSibling,
} from "api/services/dealService";
import DealFieldsEditor from "components/DealFieldsEditor";
import AnnotatedText from "components/AnnotatedText";
import usePollTask from "api/services/usePollTask";
import TopNav from "components/TopNav";
import { useNavigate } from "react-router-dom";
import SideNav from "components/SideNav";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { cloneDeep } from "lodash";
import SearchableSelect from "components/SearchableSelect";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
`;

const StyledTextarea = styled.textarea`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  resize: none;
  background-color: #181a1f;
  width: 100%;
  color: ${props => props.theme.color.closest};

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  fill: ${props => props.theme.color.closest};
  cursor: pointer;
  height: 12px;
  opacity: 0;

  :hover {
    opacity: 0.6;
  }
`;

const Tab = styled.div`
  padding: 8px 12px;
  font-weight: 500;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer1_5};
  border-bottom: 2px solid
    ${props =>
      props.isSelected
        ? props.theme.color.primary
        : props.theme.color.closer1_5};
  cursor: pointer;
  display: flex;
  gap: 4px;

  :hover {
    ${StyledTrashIcon} {
      opacity: 1;
      :hover {
        opacity: 0.6;
      }
    }
  }
`;

const StyledButton = styled.button`
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 2px 12px;
  border-radius: 4px;
  font-weight: 600;
  color: ${props => props.theme.color.closer2};
  background-color: transparent;
  cursor: pointer;
  font-size: 11px;

  :hover {
    background-color: ${props => props.theme.color.closer2};
    color: ${props => props.theme.color.furthest};
  }
`;

const RightColumn = styled.div`
  padding: 10px;
  display: grid;
  align-content: start;
  background-color: #24252a;
  /* grid-template-rows: auto auto 1fr; */
  height: 100vh;
  position: sticky;
  top: 0;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin: 8px;
  fill: ${props => props.theme.color.closest};
  cursor: pointer;
  height: 12px;
`;

const StyledInput = styled.input`
  color-scheme: dark;
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  resize: none;
  background-color: #181a1f;
  color: ${props => props.theme.color.closest};

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ParserPage = () => {
  const [schema, setSchema] = useState({});

  const [text, setText] = useState("");

  const [parsedResults, setParsedResults] = useState([]);
  const [selectedParsedResultId, setSelectedParsedResultId] = useState(null);

  const [taskId, setTaskId] = useState(null);
  const task = usePollTask(taskId);

  const [isSubmittingIngestion, setIsSubmittingIngestion] = useState(false);
  const [isMergingToDeal, setIsMergingToDeal] = useState(false);
  const [layout, setLayout] = useState({});
  const [isPbChecked, setIsPbChecked] = useState(false);
  const [bank, setBank] = useState("");

  const [config, setConfig] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    doPopulateSchema();
    doPopulatConfig();
  }, []);

  const doPopulatConfig = async () => {
    const { data } = await getConfig();
    setConfig(data);
  };

  useEffect(() => {
    if (!task?.parsedResultIds?.length) {
      return;
    }

    setParsedResults([]);
    doPopulateParsedResults(task?.parsedResultIds);
    setSelectedParsedResultId(task?.parsedResultIds?.[0]);
  }, [task?.parsedResultIds?.length]);

  const doPopulateParsedResults = async parsedResultIds => {
    const parsedResultPromises = parsedResultIds.map(async parsedResultId => {
      if (!parsedResultId) {
        return;
      }
      const { data } = await getParsedResult(parsedResultId);
      return data;
    });

    const results = await Promise.all(parsedResultPromises);
    setParsedResults(results);
  };

  const updateParsedResult = (id, newParsedResult) => {
    if (!parsedResults?.length) {
      setParsedResults([{ _id: id, ...newParsedResult }]);
      return;
    }

    const newParsedResults = parsedResults.map(parsedResult =>
      parsedResult?._id === id
        ? { ...parsedResult, ...newParsedResult }
        : parsedResult
    );
    setParsedResults(newParsedResults);
  };

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  const doPostIngestion = async () => {
    setIsSubmittingIngestion(true);
    setParsedResults([]);
    setTaskId(null);
    const { data } = await postAdhocIngestions(
      {},
      { text, bank: isPbChecked ? bank : "" }
    );
    setTaskId(data?._id);
    setIsSubmittingIngestion(false);
  };

  const doAddParsedResult = async () => {
    const { data } = await postParsedResultsCreateSibling(
      selectedParsedResultId
    );
    setParsedResults([...parsedResults, data]);
  };

  const doDeleteParsedResult = async id => {
    setParsedResults(
      parsedResults.filter(parsedResult => parsedResult?._id !== id)
    );
    await deleteParsedResults(id);
  };

  const selectedParsedResult = parsedResults.findLast(
    parsedResult => parsedResult?._id === selectedParsedResultId
  );

  let isIngesting = isSubmittingIngestion;
  if (taskId) {
    isIngesting = true;
  }
  if (task?.status === "DONE" || task?.status === "FAILED") {
    isIngesting = false;
  }

  let msg = "";
  if (task?.status === "FAILED") {
    msg = "Failed. Please try again.";
  }
  if (task?.status === "DONE" && !task?.parsedResultIds?.length) {
    msg = "No annotations found.";
  }
  if (isIngesting) {
    msg = "";
  }

  return (
    <Container
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr 400px",
        gap: "10px",
        opacity: isMergingToDeal ? 0.5 : 1,
      }}
    >
      <div style={{ width: "60px" }} />
      <SideNav />

      <div
        style={{
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Tabs>
          {!parsedResults?.length && <Tab isSelected>Tranche 1</Tab>}
          {parsedResults.map((parsedResult, i) => (
            <Tab
              key={parsedResult?._id}
              isSelected={parsedResult?._id === selectedParsedResultId}
              onClick={() => setSelectedParsedResultId(parsedResult?._id)}
            >
              Tranche {i + 1}
              <StyledTrashIcon
                style={{ display: i === 0 ? "none" : "block" }}
                onClick={e => {
                  e.stopPropagation();
                  doDeleteParsedResult(parsedResult?._id);
                }}
              />
            </Tab>
          ))}
          <StyledPlusIcon onClick={doAddParsedResult} />
        </Tabs>
        <DealFieldsEditor
          fields={selectedParsedResult?.extractedFields}
          layout={layout}
          onNewLayout={newLayout => setLayout(newLayout)}
          schema={schema}
          onChange={extractedFields =>
            updateParsedResult(selectedParsedResultId, { extractedFields })
          }
          isJsonBtnVisible
          dealId="-"
          onClickSave={async () => {
            setIsMergingToDeal(true);

            const newExtractedFields = cloneDeep(
              selectedParsedResult?.extractedFields
            );
            if (newExtractedFields?.name?.value?.includes("[")) {
              delete newExtractedFields?.name;
            }

            await patchParsedResult(
              selectedParsedResultId,
              {},
              {
                extractedFields: newExtractedFields,
              }
            );
            // navigate("/");
            setIsMergingToDeal(false);
          }}
        />
      </div>

      <RightColumn
        style={{
          display: "grid",
          alignContent: "start",
          justifyItems: "start",

          gap: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Is PB?
            <StyledInput
              value={isPbChecked}
              type="checkbox"
              onChange={e => setIsPbChecked(e.target.checked)}
            />
          </label>
          {/* <StyledInput
            value={senderEmail}
            onChange={e => setSenderEmail(e.target.value)}
            style={{
              visibility: isPbChecked ? "visible" : "hidden",
              width: "200px",
            }}
            placeholder="Sender email"
          /> */}
          <SearchableSelect
            placeholder="Bank name"
            value={bank}
            options={
              config?.banks?.map(name => ({
                name,
                value: name,
              })) || []
            }
            onChange={e => setBank(e.target.value)}
            style={{
              borderBottom: "none",
              fontSize: "13px",
              height: "100%",
              backgroundColor: "#181a1f",
              visibility: isPbChecked ? "visible" : "hidden",
              width: "200px",
            }}
          />
        </div>
        <StyledTextarea
          text={text}
          onChange={e => setText(e.target.value)}
          style={{ height: "200px" }}
          placeholder="Content to parse"
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <StyledButton
            style={{
              opacity: isIngesting ? 0.5 : 1,
              pointerEvents: isIngesting ? "none" : "auto",
            }}
            onClick={doPostIngestion}
          >
            Parse
          </StyledButton>
          {msg ? (
            <div>{msg}</div>
          ) : (
            <progress style={{ opacity: isIngesting ? 1 : 0 }} />
          )}
        </div>

        <AnnotatedText
          text={selectedParsedResult?.text}
          schema={schema}
          parsedResult={selectedParsedResult?.extractedFields}
          onChangeParsedResult={extractedFields =>
            updateParsedResult(selectedParsedResultId, { extractedFields })
          }
        />
      </RightColumn>

      {/* <div style={{ borderTop: "1px solid #eaeaea", paddingTop: "12px" }}>
        <button
          onClick={async () => {
            setIsMergingToDeal(true);
            await patchParsedResult(
              selectedParsedResultId,
              {},
              {
                extractedFields: selectedParsedResult?.extractedFields,
              }
            );
            setParsedResults([]);
            navigate("/");
            setIsMergingToDeal(false);
          }}
        >
          Save
        </button>
      </div> */}
    </Container>
  );
};

export default ParserPage;
