import { format } from "date-fns";
import { isNil, last } from "lodash";

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getCookieByName = cookieName => {
  var match = document.cookie.match(
    new RegExp("(^| )" + cookieName + "=([^;]+)")
  );
  if (match) return match[2];
};

export const isFalsey = val => !val;

export const strToColor = str => {
  let hash = 0;
  str.split("").forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

export function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

/*
{
  a: {
    b: {
      x: 1,
      c: {
        d: 1
      }
      c: [
        { d: 1 },
        12,
      ]
    }
  }

  --->

  [
    "a.b.c.d",
    "a.b.x",
    "a.b.c[0].d",
    "a.b.c[1]",
  ]
}
*/
export const getObjectPaths = obj => {
  const flattened = flattenObject(obj);
  return Object.keys(flattened);
};

export const safeFormat = (date, formatString) => {
  try {
    return format(new Date(date), formatString);
  } catch {
    return "";
  }
};

export const parseJson = str => {
  let result = str;
  try {
    result = JSON.parse(str?.replaceAll("\n", " "));
  } catch {
    result = str;
  }
  if (result === 0) {
    return 0;
  }
  return result || [];
};

export const isArchivedDeal = deal => {
  const nowIsoStr = new Date().toISOString();
  const today = new Date(nowIsoStr?.split("T")?.[0]);
  // const today = new Date("2024-02-10");
  const updatedAt = new Date(deal?.updatedAt);

  if (deal?.extractedFields?.Status?.value === "Priced" && updatedAt < today) {
    return true;
  }

  return false;
};

export const formatLongNumber = (num, digits = 3) => {
  try {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  } catch {
    return num;
  }
};

export const getDisplayValue = ({ record, columnName, columnType = "-" }) => {
  let recordValue = last(`${record?.[columnName]?.value}`?.split("="));
  if (
    record?.[columnName]?.value === "" ||
    isNil(record?.[columnName]?.value)
  ) {
    return "-";
  }

  const isYearColumn = columnName?.toLowerCase()?.includes("year");

  if (typeof recordValue === "number" && !isYearColumn) {
    recordValue = formatLongNumber(recordValue);
  }

  if (columnName === "Loan Amount" && typeof recordValue === "string") {
    recordValue = recordValue?.replaceAll(",", "");
    recordValue = formatLongNumber(parseFloat(recordValue));
  }

  if (columnType === "TEXT") {
    return recordValue;
  }

  if (columnType === "PERCENTAGE") {
    return `${recordValue}%`;
  }

  if (columnType === "CURRENCY") {
    return `$${recordValue}`;
  }

  if (record?.[columnName]?.unit === "USD") {
    recordValue = `$${recordValue}`;
  }

  return recordValue;
};
