import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  getDeals,
  getExtractionSchema,
  patchDeal,
} from "api/services/dealService";
import RecordsCards from "components/RecordsCards";
import TopNav from "components/TopNav";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  padding: 20px;
  padding-top: 10px;
  gap: 20px;
`;

const AllRecordsPage = () => {
  const [allRecords, setAllRecords] = useState([]);
  const [schema, setSchema] = useState({});

  useEffect(() => {
    doPopulateSchema();
  }, []);

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  useEffect(() => {
    doPopulateDeals();

    const intervalId = setInterval(() => {
      doPopulateDeals();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const doPopulateDeals = async () => {
    const { data, error } = await getDeals();
    if (error?.type === "Unauthorised") {
      window.location = "/login";
      return;
    }
    setAllRecords(data);
  };

  const doPatchRecord = async (id, body) => {
    const { data: updatedRecord } = await patchDeal(id, {}, body);
    const newRecords = allRecords.map(record =>
      record._id === id ? updatedRecord : record
    );
    setAllRecords(newRecords);
  };

  // const missingRecords = allRecords?.filter(
  //   record => getMissingFields(record, schema)?.length > 0
  // );
  const missingRecords = allRecords;

  return (
    <Container>
      <TopNav />

      <RecordsCards
        records={missingRecords}
        onClickSaveRecord={(id, newFields) => doPatchRecord(id, newFields)}
      />
    </Container>
  );
};

export default AllRecordsPage;
