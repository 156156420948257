import styled from "styled-components";
import { useState, useEffect } from "react";

import { getDeals, getEmails } from "api/services/dealService";
import { EmailIcon } from "components/ui/Icons";
import NavHeader from "components/widgets/NavHeader";
import { Link } from "react-router-dom";
import { isArchivedDeal } from "utils/common";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr auto;
  gap: 20px;
  align-items: center;
  padding-bottom: 0px;
  border-bottom: 1px solid #d7d7d7;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  color: ${props => (props.isSelected ? "#262626" : "#787878")};
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid
    ${props => (props.isSelected ? props.theme.color.primary : "transparent")};
  :hover {
    color: #262626;
  }
`;

const TopNav = () => {
  const [emailRecords, setEmailRecords] = useState([]);
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    doPopulateEmails();
    doPopulateDeals();

    const intervalId = setInterval(() => {
      doPopulateDeals();
      doPopulateEmails();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const doPopulateEmails = async () => {
    const { data } = await getEmails();
    setEmailRecords(
      data?.filter(record => record?.parsingStatus !== "DONE") || []
    );
  };

  const doPopulateDeals = async () => {
    const { data } = await getDeals();
    setDeals(data);
  };

  const archivedDealsCount = deals.filter(isArchivedDeal).length;
  const unarchivedDealsCount = deals.length - archivedDealsCount;

  return (
    <Container>
      <NavLink to="/" isSelected={window?.location?.pathname === "/"}>
        Dashboard ({unarchivedDealsCount} deals)
      </NavLink>
      <NavLink
        to="/parser"
        isSelected={window?.location?.pathname === "/parser"}
      >
        Parser
      </NavLink>
      <NavLink
        to="/archive"
        isSelected={window?.location?.pathname === "/archive"}
      >
        Archive ({archivedDealsCount} deals)
      </NavLink>
      {/* <NavLink to="/all" isSelected={window?.location?.pathname === "/all"}>
        All deals
      </NavLink> */}
      <NavLink
        to="/missing"
        isSelected={window?.location?.pathname === "/missing"}
        style={{ justifySelf: "end" }}
      >
        <EmailIcon height="14px" style={{ fill: "#d4a600" }} /> (
        {emailRecords.length} emails)
      </NavLink>
      <NavHeader />
    </Container>
  );
};

export default TopNav;
