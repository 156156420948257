import { useState } from "react";
import styled from "styled-components";

import TextInput from "components/ui/TextInput";
import { getAccessToken } from "api/services/authenticationService";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";

const ModalContent = styled.div`
  width: 450px;
  height: 350px;
`;

const Gap = styled.div`
  height: 10px;
`;

const Logo = styled.img`
  margin: 0;
  width: 50px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 15px;
  color: ${props => props.theme.color.error};
  width: max-content;
  left: calc(50% - max-content);
  text-align: center;
  width: 300px;
`;

const Form = styled.form`
  width: 400;
  padding: 35px 35px 35px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  position: relative;
  padding-top: 40px;
`;

const LoginModal = ({ open, handleClose }) => {
  const [inputUserName, setInputUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const logInAndRedirect = async e => {
    setIsLoading(true);
    e.preventDefault();
    const { data, error } = await getAccessToken(inputUserName, password);
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    localStorage.setItem("accessToken", data.accessToken);
    document.cookie = `accessToken=${data.accessToken};domain=.boltzbit.com`;
    window.location = "/";
  };

  return (
    <Modal
      open={open}
      handleClose={() => {
        handleClose();
      }}
    >
      <ModalContent>
        <Form onSubmit={logInAndRedirect}>
          <Logo src={`/images/logo-black.png`} />
          <Gap height="20px" />
          <TextInput
            title="Username"
            value={inputUserName}
            onNewInput={newUsername => setInputUserName(newUsername)}
            isRequired
          />
          <Gap />
          <TextInput
            title="Password"
            value={password}
            type="password"
            onNewInput={newPassword => setPassword(newPassword)}
            isRequired
          />
          <ButtonContainer>
            <ErrorMessage>{error?.message}</ErrorMessage>
            <Button type="submit" value={isLoading ? "Loading..." : "Login"} />
          </ButtonContainer>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
