import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";
import ItemListPopover from "components/ui/ItemListPopover";
import LoginModal from "components/LoginModal";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import { Menu } from "@material-ui/icons";
import ConfirmationModalTrigger from "components/ConfirmationModalTrigger";
import { sleep } from "utils/common";
import { postClear } from "api/services/dealService";

const NavBody = styled.div`
  box-sizing: content-box;
  width: ${props => props.hasStickySearchBar && "100%"};
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
  border-bottom: 1px solid
    ${props =>
      props.hasStickySearchBar ? props.theme.color.closer1 : "transparent"};
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const PopoverNavLink = styled(Link)`
  padding: 10px 100px 10px 10px;
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  white-space: nowrap;
  transition: background-color 0.2s;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const ModalOption = styled.div`
  padding: 10px 100px 10px 10px;
  display: block;
  font-weight: 600;
  text-decoration: none;
  color: ${props => props.theme.color.error};
  white-space: nowrap;
  transition: background-color 0.2s;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const UserAvatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.color.primary};
`;

const LoginDiv = styled.div`
  color: black;
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 6px;
  width: 60px;
  font-size: 9px;
  height: 60px;
`;

const NavHeader = ({ bgColor }) => {
  const userName = getLoggedInUserName();
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const doFetchCreatorAvatar = async () => {
    const { data } = await getUserProfileByUserName(userName);
    setAvatarSrc(
      data?.image?.includes("/images/")
        ? data?.image
        : `data:image/png;base64,${data?.image}`
    );
  };

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [userName]);

  return (
    <>
      {/* <LoginModal
        open={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
      /> */}
      <NavBody bgColor={bgColor}>
        {/* {userName && <div>{userName}</div>} */}
        <LinksContainer>
          {userName ? (
            <>
              <ItemListPopover
                iconComponent={
                  <IconContainer>
                    <Menu
                      style={{
                        height: "18px",
                      }}
                    />
                    Menu
                  </IconContainer>
                }
              >
                {/* <PopoverNavLink to={"/"}>My Files</PopoverNavLink> */}
                <div
                  style={{
                    padding: "8px",
                    fontWeight: "600",
                    borderBottom: "1px solid #151515",
                  }}
                >
                  {userName}
                </div>
                <ConfirmationModalTrigger
                  message="Are you sure you want to reset? All deals will be deleted."
                  icon={<ModalOption>Reset</ModalOption>}
                  isDisabled={isResetting}
                  onClickYes={async () => {
                    setIsResetting(true);
                    await postClear();
                    window.location.reload();
                  }}
                />
                <PopoverNavLink
                  to={"/"}
                  onClick={() => {
                    setLoginTokenInClientStorage("PUBLIC");
                    window.location = "/";
                  }}
                >
                  Logout
                </PopoverNavLink>
              </ItemListPopover>
            </>
          ) : (
            <LoginDiv
              onClick={() => {
                setShowLoginModal(true);
              }}
            >
              Login
            </LoginDiv>
          )}
        </LinksContainer>
      </NavBody>
    </>
  );
};

export default NavHeader;
