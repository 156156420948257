import SearchableSelect from "components/SearchableSelect";
import { CrossIcon, PencilIcon } from "components/ui/Icons";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0;
  cursor: pointer;
  fill: white;
  :hover {
    opacity: 0.5;
  }
  margin-left: 4px;
`;

const ItemSpan = styled.div`
  padding: 4px 8px;
  padding-left: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin-right: 4px;
  background-color: ${props => props.theme.color.closer0};
  :hover {
    background-color: ${props => props.theme.color.closer0};
    ${StyledCrossIcon} {
      opacity: 1;
    }
  }
  font-size: 13px;
  color: ${props => props.theme.color.closest};
  white-space: nowrap;
  overflow: hidden;
`;

const ItemText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Input = styled.input`
  color-scheme: dark;
  width: 100%;
  font-family: "Inter";
`;

const StringItemsContainer = styled.div`
  padding: 2px 0;
  min-height: 18px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const GreyItemSpan = styled.div`
  padding: 0 2px;
  background-color: ${props => props.theme.color.closer1};
`;

const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  fill: white;
  :hover {
    opacity: 0.6;
  }
`;

const Container = styled.div`
  overflow: auto;
`;

const ItemsContainer = styled.div`
  min-height: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const ArrayInput = ({
  placeholder = "Add Partner Bank+",
  items = null,
  value = [],
  onChange = newValue => {},
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [strValue, setStrValue] = useState("");

  useEffect(() => {
    if (value instanceof Array) {
      setStrValue(value.join(" / "));
    }
  }, [JSON.stringify(value)]);

  if (typeof value === "string") {
    value = [value];
  }

  if (items === "string") {
    return (
      <Container>
        <StringItemsContainer>
          {!isEditing &&
            value?.map((item, i) => (
              <GreyItemSpan key={i}>{item}</GreyItemSpan>
            ))}
          {isEditing && (
            <Input
              placeholder="bank1 / bank2 / bank3"
              autoFocus
              value={strValue}
              onChange={e => setStrValue(e.target.value)}
              onBlur={() => {
                onChange(strValue.split("/")?.map(val => val?.trim()));
                setIsEditing(false);
              }}
            />
          )}
          {!isEditing && (
            <StyledPencilIcon
              onClick={() => setIsEditing(!isEditing)}
              height="12px"
            />
          )}
        </StringItemsContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ItemsContainer>
        <SearchableSelect
          value=""
          placeholder={placeholder}
          options={items?.symbols?.map(symbol => ({
            name: symbol,
            value: symbol,
          }))}
          onChange={e => {
            if (!e.target.value) {
              return;
            }
            onChange([...value, e.target.value]);
          }}
          style={{
            borderBottom: "none",
            width: "130px",
            marginRight: "8px",
            fontSize: "13px",
          }}
        />
        {value?.map((item, i) => (
          <ItemSpan key={i}>
            <ItemText>{item}</ItemText>
            <StyledCrossIcon
              style={{ height: "12px" }}
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                e.stopPropagation();
                onChange(value.filter((_, j) => j !== i));
              }}
            />
          </ItemSpan>
        ))}
      </ItemsContainer>
    </Container>
  );
};

export default ArrayInput;
