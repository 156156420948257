// import { createGlobalStyle } from "styled-components";

// import InterBold from "fonts/Inter-Bold.ttf";
// import InterRegular from "fonts/Inter-Regular.ttf";

// const Fonts = createGlobalStyle`
//   @font-face {
//     font-family: "Inter";
//     src: url(${InterBold}) format("truetype");
//     font-weight: 600;
//   }

//   @font-face {
//     font-family: "Inter";
//     src: url(${InterRegular}) format("truetype");
//     font-weight: 400;
//   }

//   body {
//     font-family: "Inter", sans-serif;
//     font-weight: 400;
//     color: ${props => props.textColor};
//     font-size: 11px;
//   }
// `;

// export default Fonts;

import { createGlobalStyle } from "styled-components";

import InterVariable from "fonts/Inter-VariableFont_wght.ttf";

const Fonts = createGlobalStyle`
  @font-face {
    font-family: "Inter", sans-serif;
    src: url(${InterVariable}) format("woff2-variations");
    font-weight: 100 900;
    font-size: 11px;
    font-variation-settings:
      "slnt" 0;
  }

  body {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: ${props => props.textColor};
    font-size: 11px;
  }
`;

export default Fonts;
