import styled from "styled-components";
import { useState } from "react";

import SideNav from "components/SideNav";
import { CrossIcon, HumanIcon, LogoInner, SendIcon } from "components/ui/Icons";
import { postExecuteCommand, postExecuteNlc } from "api/services/dealService";
import InputWithSuggestions from "components/InputWithSuggestions";
import { formatStatValue } from "pages";
import RecordsSummaryTable from "components/RecordsSummaryTable";
import RecordsSummaryBarChart from "components/RecordsSummaryBarChart";
import RecordsSingleNumber from "components/RecordsSingleNumber";
import RecordsSummaryLineChart from "components/RecordsSummaryLineChart";
import RecordsSummaryPieChart from "components/RecordsSummaryPieChart";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
`;

const ChatContent = styled.div`
  display: grid;
  justify-content: center;
  grid-template-rows: calc(100vh - 70px) 70px;
`;

const InputArea = styled.div`
  position: relative;
  width: 800px;
  justify-self: center;
  background-color: ${props => props.theme.color.closer0};
  background-color: #242930;
  z-index: 2;
`;

const StyledInputWithSuggestions = styled(InputWithSuggestions)`
  color-scheme: dark;
  border: 1px solid transparent;
  outline: none;
  padding: 4px 18px;
  border-radius: 4px;
  background-color: #181a1f;
  font-family: "Inter";
  width: 100%;
  height: 40px;
  font-size: 12px;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`;

const BubblesContainer = styled.div`
  padding: 12px 0;
  overflow: auto;
`;

const Bubble = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  padding: 8px 0;
  gap: 20px;
  width: 800px;
  line-height: 1.5;
  font-size: 14px;
`;

const BotLogo = styled(LogoInner)`
  fill: ${props => props.theme.color.primary};
  border: 1px solid ${props => props.theme.color.primary};
  padding: 4px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`;

const HumanLogo = styled(HumanIcon)``;

const StyledSendIcon = styled(SendIcon)`
  position: absolute;
  top: 10px;
  right: 18px;
  height: 20px;

  cursor: pointer;
  fill: #535353;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 10px;
  right: 18px;
  height: 20px;

  cursor: pointer;
  fill: #535353;
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const PulsingDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.primary};
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

const PlotContainer = styled.div`
  position: relative;
  margin-top: 8px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const PlotSelect = styled.select`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const getPlotTypeFromQuery = (query = "") => {
  if (query.toLowerCase().includes("bar")) {
    return "bar";
  }
  if (query.toLowerCase().includes("time")) {
    return "time_series";
  }
  if (query.toLowerCase().includes("pie")) {
    return "pie_chart";
  }

  return "table";
};

const PLOT_TYPES = ["table", "bar", "time_series", "pie_chart"];

const RecordsPlot = ({ records, columns, initialPlotType = "table" }) => {
  const [plotType, setPlotType] = useState(initialPlotType);

  const typeToComponent = {
    table: <RecordsSummaryTable records={records} columns={columns} />,
    bar: <RecordsSummaryBarChart records={records} columns={columns} />,
    time_series: (
      <RecordsSummaryLineChart records={records} columns={columns} />
    ),
    single_value: <RecordsSingleNumber records={records} />,
    pie_chart: <RecordsSummaryPieChart records={records} columns={columns} />,
  };

  return (
    <PlotContainer style={plotType === "table" ? { border: "none" } : {}}>
      <PlotSelect value={plotType} onChange={e => setPlotType(e.target.value)}>
        {PLOT_TYPES.map(type => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </PlotSelect>
      {typeToComponent[plotType]}
    </PlotContainer>
  );
};

const ChatPage = () => {
  const [bubbles, setBubbles] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isWaitingForAnswer, setIsWaitingForAnswer] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());

  const doPopulateAnswer = async text => {
    setIsWaitingForAnswer(true);
    const userBubble = {
      author: "user",
      text,
    };
    const newBubbles = [...bubbles, userBubble];
    setBubbles(newBubbles);

    const { data, error } = await postExecuteNlc(
      {},
      { nlc: text },
      abortController
    );
    if (error) {
      setIsWaitingForAnswer(false);
      return;
    }

    const columns = data?.columns || [];
    const filteredRecords = data?.records?.filter(record => {
      const areAllColumnsEmpty = columns.every(
        col => !record?.[col.name]?.value
      );
      return !areAllColumnsEmpty;
    });

    setBubbles([
      ...newBubbles,
      {
        author: "bot",
        text: data?.text,
        records: filteredRecords,
        columns: data?.columns,
        plotType: getPlotTypeFromQuery(text),
      },
    ]);
    setIsWaitingForAnswer(false);
  };

  return (
    <Container>
      <div style={{ width: "60px" }} />
      <SideNav />
      <ChatContent>
        <BubblesContainer>
          {bubbles?.map((bubble, i) => {
            let authorIcon = <HumanLogo />;
            if (bubble.author === "bot") {
              authorIcon = <BotLogo />;
            }
            const isLastFromAuthor =
              bubble?.author !== bubbles?.[i + 1]?.author;
            if (bubble?.author === bubbles?.[i - 1]?.author) {
              authorIcon = <div />;
            }

            return (
              <Bubble style={{ marginBottom: isLastFromAuthor ? "32px" : 0 }}>
                {authorIcon}
                <div>
                  <div>{bubble.text}</div>
                  {bubble?.records && (
                    <RecordsPlot
                      records={bubble.records}
                      columns={bubble.columns}
                      initialPlotType={bubble.plotType}
                    />
                  )}
                </div>
              </Bubble>
            );
          })}
          {isWaitingForAnswer && (
            <Bubble>
              <BotLogo />
              <div>
                <PulsingDot />
              </div>
            </Bubble>
          )}
        </BubblesContainer>
        <InputArea>
          <StyledInputWithSuggestions
            disabled={isWaitingForAnswer}
            value={userInput}
            onChange={e => setUserInput(e.target.value)}
            placeholder="Ask a question"
            onClickSuggestion={suggestionText => {
              setUserInput(suggestionText);
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                doPopulateAnswer(userInput);
                setUserInput("");
              }
            }}
          />
          {isWaitingForAnswer ? (
            <StyledCrossIcon
              onClick={() => {
                abortController.abort();
                setAbortController(new AbortController());
              }}
            />
          ) : (
            <StyledSendIcon
              onClick={() => {
                doPopulateAnswer(userInput);
                setUserInput("");
              }}
            />
          )}
        </InputArea>
      </ChatContent>
    </Container>
  );
};

export default ChatPage;
