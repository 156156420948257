import { formatStatValue } from "pages";
import styled from "styled-components";

const Td = styled.td`
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.color.closest}55;
  font-weight: 300;
  transition: opacity 0.2s, border 0.2s;
`;

const Th = styled.th`
  padding: 8px;
  border: 1px solid ${props => props.theme.color.closest}55;
  font-weight: 500;
  text-align: left;
  transition: opacity 0.2s, border 0.2s;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const RecordsSummaryTable = ({ columns = [], records = [] }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map((col, colIndex) => (
            <Th key={colIndex}>{col.name}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {records?.map((record, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <Td key={colIndex}>
                {formatStatValue(col?.name, record[col.name]?.value)}
              </Td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default RecordsSummaryTable;
