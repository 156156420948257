import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  getConfig,
  getExtractionSchema,
  patchConfig,
} from "api/services/dealService";
import SideNav from "components/SideNav";
import { CrossIcon, PlusIcon } from "components/ui/Icons";
import SearchableSelect from "components/SearchableSelect";
import { fromPairs, toPairs } from "lodash";
import ArrayInput from "components/ArrayInput";

const Container = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  background-color: black;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 2px 0;
`;

const Content = styled.div`
  grid-column: 2;
  padding: 10px;
  display: grid;
  gap: 10px;
  align-content: start;
  justify-items: start;
  ${props => props.isDisabled && "opacity: 0.5;"}
  padding-bottom: 350px;
  min-height: 100vh;
`;

const StyledPlusIcon = styled(PlusIcon)`
  fill: #eaeaea;
  height: 14px;
  width: 14px;
  cursor: pointer;

  :hover {
    fill: white;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  opacity: 0;
  fill: #eaeaea;
  height: 14px;
  width: 14px;
  cursor: pointer;

  :hover {
    fill: white;
  }
`;

const Input = styled.input`
  color: white;
  color-scheme: dark;
  padding: 2px 8px;
  background-color: #24252a;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  color-scheme: dark;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const PairContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  justify-items: start;
  gap: 10px;

  :hover {
    ${StyledCrossIcon} {
      opacity: 1;
    }
  }
`;

const StyledButton = styled.button`
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 2px 12px;
  border-radius: 4px;
  font-weight: 600;
  color: ${props => props.theme.color.closer2};
  background-color: transparent;
  cursor: pointer;
  font-size: 11px;

  :hover {
    background-color: ${props => props.theme.color.closer2};
    color: ${props => props.theme.color.furthest};
  }
`;

const SettingsPage = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [config, setConfig] = useState(null);
  const [emailToBankPairs, setEmailToBankPairs] = useState([["", ""]]);
  const [bankToLeiPairs, setBankToLeiPairs] = useState([["", ""]]);
  const [dealLevelFields, setDealLevelFields] = useState([]);
  const [schema, setSchema] = useState({});

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  useEffect(() => {
    doPopulatConfig();
    doPopulateSchema();
  }, []);

  const doPopulatConfig = async () => {
    const { data } = await getConfig();

    setEmailToBankPairs(toPairs(data?.senderEmailToBank || {}));
    setBankToLeiPairs(toPairs(data?.bankToLei || {}));
    setDealLevelFields(data?.dealLevelFields || []);
    setConfig(data);
  };

  const doSaveConfig = async () => {
    setIsSaving(true);

    const body = {
      senderEmailToBank: fromPairs(emailToBankPairs),
      bankToLei: fromPairs(bankToLeiPairs),
      dealLevelFields,
    };

    const { data } = await patchConfig({}, body);

    setEmailToBankPairs(toPairs(data?.senderEmailToBank || {}));
    setBankToLeiPairs(toPairs(data?.bankToLei || {}));
    setDealLevelFields(data?.dealLevelFields || []);
    setConfig(data);
    setIsSaving(false);
  };

  return (
    <Container>
      <SideNav style={{ position: "fixed" }} />
      <Content isDisabled={isSaving}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            width: "100%",
          }}
        >
          <Title>Sender email to bank</Title>
          <StyledButton onClick={doSaveConfig}>Save</StyledButton>
        </div>
        {emailToBankPairs.map((pair, index) => (
          <PairContainer key={index}>
            <Input
              placeholder="@hsbc.com"
              value={pair?.[0]}
              onChange={e =>
                setEmailToBankPairs(prev => {
                  const newPairs = [...prev];
                  newPairs[index][0] = e.target.value;
                  return newPairs;
                })
              }
            />
            →
            <SearchableSelect
              placeholder="Bank name"
              value={pair?.[1]}
              options={
                config?.banks?.map(name => ({
                  name,
                  value: name,
                })) || []
              }
              onChange={e => {
                setEmailToBankPairs(prev => {
                  const newPairs = [...prev];
                  newPairs[index][1] = e.target.value;
                  return newPairs;
                });
              }}
              style={{
                borderBottom: "none",
                width: "280px",
                fontSize: "13px",
                height: "100%",
              }}
            />
            <StyledCrossIcon
              onClick={() =>
                setEmailToBankPairs(prev => prev.filter((_, i) => i !== index))
              }
            />
          </PairContainer>
        ))}
        <StyledPlusIcon
          onClick={() => setEmailToBankPairs(prev => [...prev, ["", ""]])}
        />

        <Title style={{ marginTop: "40px" }}>Bank to LEI</Title>
        {bankToLeiPairs.map((pair, index) => (
          <PairContainer key={index}>
            <SearchableSelect
              placeholder="Bank name"
              value={pair?.[0]}
              options={
                config?.banks?.map(name => ({
                  name,
                  value: name,
                })) || []
              }
              onChange={e => {
                setBankToLeiPairs(prev => {
                  const newPairs = [...prev];
                  newPairs[index][0] = e.target.value;
                  return newPairs;
                });
              }}
              style={{
                borderBottom: "none",
                width: "280px",
                fontSize: "13px",
                height: "100%",
              }}
            />
            →
            <Input
              placeholder="LEI"
              value={pair?.[1]}
              onChange={e =>
                setBankToLeiPairs(prev => {
                  const newPairs = [...prev];
                  newPairs[index][1] = e.target.value;
                  return newPairs;
                })
              }
            />
            <StyledCrossIcon
              onClick={() =>
                setBankToLeiPairs(prev => prev.filter((_, i) => i !== index))
              }
            />
          </PairContainer>
        ))}
        <StyledPlusIcon
          onClick={() => setBankToLeiPairs(prev => [...prev, ["", ""]])}
        />

        <Title style={{ marginTop: "40px" }}>Deal Level Fields</Title>
        <div style={{ color: "#898989", marginBottom: "4px" }}>
          Updates to these fields will apply to all tranches in a deal
        </div>
        <ArrayInput
          placeholder="Field name"
          value={dealLevelFields}
          onChange={newValue => setDealLevelFields(newValue)}
          items={{ symbols: schema?.fields?.map(field => field?.name) }}
        />
      </Content>
    </Container>
  );
};

export default SettingsPage;
