import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { cloneDeep, get, isEqual, isNil, last, merge, set } from "lodash";

import {
  getDeal,
  getExtractionSchema,
  getParsedResults,
  patchDeal,
  patchParsedResult,
  postFormatValue,
} from "api/services/dealService";
import { getObjectPaths, strToColor } from "utils/common";
import DealFieldsEditor from "components/DealFieldsEditor";
import SpanWithHoverTooltip from "components/SpanWithHoverTooltip";
import { CrossIcon, PencilIcon } from "components/ui/Icons";
import SearchableSelect from "components/SearchableSelect";
import { useParams } from "react-router-dom";
import SideNav from "components/SideNav";

// segment { text, meta, fieldName }
export const getTextSegments = (
  text,
  fields,
  onMetaFilter = meta => !meta?.inSubject,
  inSubject = false
) => {
  const paths = getObjectPaths(fields)
    ?.filter(path => !path.includes(".meta"))
    ?.map(path => path.replaceAll(/\.value.*/g, ""));

  const metas = [];
  paths?.forEach(path => {
    const meta = get(fields, `${path}.meta`);
    if (meta) {
      metas.push({ ...meta, fieldName: path });
    }
  });

  const sel = window.getSelection();
  if (sel?.toString()) {
    metas.push({
      startInd: Math.min(sel?.anchorOffset, sel?.focusOffset),
      endInd: Math.max(sel?.anchorOffset, sel?.focusOffset),
      inSubject,
      fieldValue: sel?.toString(),
      fieldName: "TBD",
    });
  }

  const segments = [];
  let currentSegment = { text: "", meta: undefined };
  let charIndex = 0;

  while (charIndex < text?.length) {
    const metaAtIndex = metas
      ?.filter(onMetaFilter)
      ?.find(m => charIndex >= m?.startInd && charIndex < m?.endInd);

    if (JSON.stringify(metaAtIndex) !== JSON.stringify(currentSegment.meta)) {
      segments.push(currentSegment);
      currentSegment = { text: "", meta: metaAtIndex };
    }

    currentSegment.text += text[charIndex];
    charIndex++;
  }

  segments.push(currentSegment);

  return segments;
};

export const getSelectOptionsFromSchema = schema => {
  const options = [];

  schema?.fields?.forEach(field => {
    if (!field?._meta?.groupName) {
      return;
    }

    if (field?.type === "record") {
      field?.fields?.forEach(subField => {
        options?.push({
          name: `${field?.name}.${subField?.name}`,
          value: `${field?.name}.${subField?.name}`,
          group: field?._meta?.groupName,
        });
      });
      return;
    }

    options?.push({
      name: field?.name,
      value: field?.name,
      group: field?._meta?.groupName,
    });
  });

  return options;
};

const ModalContent = styled.div`
  padding: 10px;
  white-space: pre-wrap;
  height: 90vh;
  line-height: 1.2;
  overflow: hidden;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  outline: none;

  display: grid;
  grid-template-columns: 1fr 400px;
`;

const EmailHtml = styled.div`
  position: relative;
  overflow: auto;
  color: ${props => props.theme.color.closest};
  line-height: 1.5;
  font-size: 11px;
`;

const EmailSubject = styled.div`
  font-weight: 600;
  position: relative;
  color: ${props => props.theme.color.primary};
  border-bottom: 2px solid ${props => props.theme.color.primary};
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const StyledSelect = styled.select`
  background-color: transparent;
  color: white;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  resize: none;
  font-size: 11px;
  margin-bottom: 8px;
  padding: 4px 0;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  fill: white;
  height: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const LabelTip = styled.div`
  padding: 2px 4px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 11px;
`;

const Container = styled.div`
  white-space: pre-wrap;
  /* height: 90vh; */
  line-height: 1.2;
  overflow: hidden;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  outline: none;

  display: grid;
  grid-template-columns: auto 1fr 400px;
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
`;

const RightColumn = styled.div`
  padding: 10px;
  display: grid;
  align-content: start;
  background-color: #24252a;
  grid-template-rows: auto auto auto 1fr;
  height: 100vh;
  position: sticky;
  top: 0;
`;

const getExtractedFieldsFromParsedResults = (
  extractedFields,
  parsedResults,
  newPath
) => {
  if (extractedFields?.[newPath]?.meta?.manuallyEntered) {
    return extractedFields;
  }

  const newExtractedFields = cloneDeep(extractedFields);
  const allParsedResults = parsedResults?.map(parsedResult => {
    const pathValue = get(parsedResult?.extractedFields, newPath);
    const partialResult = {};
    set(partialResult, newPath, pathValue);
    return partialResult;
  });
  const mergedParsedResult = merge(...allParsedResults);

  const mergedExtractedFields = merge(newExtractedFields, mergedParsedResult);
  return mergedExtractedFields;
};

const DealPage = () => {
  const { dealId } = useParams();

  const [schema, setSchema] = useState({});
  const [record, setRecord] = useState({});
  const [selectedEmailId, setSelectedEmailId] = useState("");
  const [parsedResults, setParsedResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectionInSubject, setIsSelectionInSubject] = useState(false);

  const [extractedFields, setExtractedFields] = useState({});
  const [layout, setLayout] = useState(undefined);

  const [selectedPath, setSelectedPath] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);

  const emailBodyRef = useRef(null);

  const [parsedResultsSegments, setParsedResultsSegments] = useState([]);
  const [subjectSegments, setSubjectSegments] = useState([]);

  const selectedParsedResult =
    parsedResults?.find(
      parsedResult => parsedResult?._id === selectedEmailId
    ) || {};

  useEffect(() => {
    doPopulateSchema();
    doPopulateDealRecord();
  }, [dealId]);

  useEffect(() => {
    const { text, subject } = selectedParsedResult || {};
    let newParsedResultsSegments = getTextSegments(
      text,
      selectedParsedResult?.extractedFields
    );
    let newSubjectSegments = getTextSegments(
      subject,
      selectedParsedResult?.extractedFields,
      meta => meta?.inSubject,
      true
    );

    if (isSelectionInSubject) {
      newParsedResultsSegments = parsedResultsSegments?.filter(
        segment => segment?.meta?.fieldName !== "TBD"
      );
    } else {
      newSubjectSegments = newSubjectSegments?.filter(
        segment => segment?.meta?.fieldName !== "TBD"
      );
    }

    setParsedResultsSegments(newParsedResultsSegments);
    setSubjectSegments(newSubjectSegments);
  }, [selectedText, JSON.stringify(selectedParsedResult)]);

  useEffect(() => {
    setExtractedFields(record?.extractedFields);
  }, [JSON.stringify(record?.extractedFields)]);

  useEffect(() => {
    setLayout(record?.layout);
  }, [JSON.stringify(record?.layout)]);

  useEffect(() => {
    if (!isEqual(record?.layout, layout) && record?._id) {
      doPatchRecord(record?._id, { layout });
    }
  }, [JSON.stringify(layout)]);

  useEffect(() => {
    if (!record?.parsedResultIds?.length) {
      return;
    }
    doPopulateParsedResults(record?.parsedResultIds);
  }, [JSON.stringify(record?.parsedResultIds)]);

  const doPatchRecord = async (id, body) => {
    const { data: updatedRecord } = await patchDeal(id, {}, body);
    setRecord(updatedRecord);
  };

  const doPopulateParsedResults = async ids => {
    const { data } = await getParsedResults({ ids });
    setParsedResults(data);
    setSelectedEmailId(last(data)?._id);
  };

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  const doPopulateDealRecord = async () => {
    const { data } = await getDeal(dealId);
    setRecord(data);
  };

  const onSelectPath = async newPath => {
    setIsLoading(true);
    setSelectedPath(newPath);

    if (newPath === "-" || newPath === "") {
      setIsLoading(false);
      return;
    }

    let tbdSegment = parsedResultsSegments?.find(
      segment => segment?.meta?.fieldName === "TBD"
    );
    if (isSelectionInSubject) {
      tbdSegment = subjectSegments?.find(
        segment => segment?.meta?.fieldName === "TBD"
      );
    }

    let { startInd, endInd, fieldValue } = tbdSegment?.meta || {};
    if (!fieldValue) {
      setIsLoading(false);
      return;
    }

    const { data } = await postFormatValue(
      {},
      {
        dealId: record?._id,
        text: fieldValue,
        field: newPath,
      }
    );
    fieldValue = data?.value || fieldValue;

    const newExtractedFieldsParsedResult = cloneDeep(
      selectedParsedResult?.extractedFields
    );
    set(newExtractedFieldsParsedResult, `${newPath}.meta`, {
      startInd,
      endInd,
      emailId: selectedEmailId,
      inSubject: isSelectionInSubject,
    });
    set(newExtractedFieldsParsedResult, `${newPath}.value`, fieldValue);

    const newParsedResults = parsedResults.map(parsedResult => {
      if (parsedResult?._id === selectedEmailId) {
        return {
          ...parsedResult,
          extractedFields: newExtractedFieldsParsedResult,
        };
      }
      return parsedResult;
    });

    await patchParsedResult(
      selectedEmailId,
      {},
      { extractedFields: newExtractedFieldsParsedResult }
    );

    setParsedResults(newParsedResults);

    // const newExtractedFields = getExtractedFieldsFromParsedResults(
    //   extractedFields,
    //   newParsedResults,
    //   newPath
    // );
    setExtractedFields(newExtractedFieldsParsedResult);
    setSelectedPath("");

    setIsLoading(false);
  };

  const doRemoveMeta = async fieldName => {
    setIsLoading(true);
    const newExtractedFieldsParsedResult = cloneDeep(
      selectedParsedResult?.extractedFields
    );
    set(newExtractedFieldsParsedResult, `${fieldName}.meta`, {});
    set(newExtractedFieldsParsedResult, `${fieldName}.value`, null);

    const newParsedResults = parsedResults.map(parsedResult => {
      if (parsedResult?._id === selectedEmailId) {
        return {
          ...parsedResult,
          extractedFields: newExtractedFieldsParsedResult,
        };
      }
      return parsedResult;
    });

    await patchParsedResult(
      selectedEmailId,
      {},
      { extractedFields: newExtractedFieldsParsedResult }
    );

    // const newExtractedFields = getExtractedFieldsFromParsedResults(
    //   extractedFields,
    //   newParsedResults,
    //   fieldName
    // );
    setParsedResults(newParsedResults);

    setExtractedFields(newExtractedFieldsParsedResult);

    setIsLoading(false);
  };

  const selectOptions = getSelectOptionsFromSchema(schema);

  return (
    <Container>
      <div style={{ width: "60px" }} />
      <SideNav />
      <div
        style={{
          padding: "10px",
          paddingTop: 0,
          height: "100vh",
          overflow: "auto",
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <DealFieldsEditor
          dealId={record?._id}
          fields={extractedFields}
          layout={layout}
          onNewLayout={newLayout => setLayout(newLayout)}
          schema={schema}
          onChange={newFields => setExtractedFields(newFields)}
          onClickSave={async () => {
            setIsLoading(true);
            await doPatchRecord(dealId, { extractedFields, layout });
            setIsLoading(false);
          }}
        />
      </div>

      <RightColumn>
        <EmailSubject
          onMouseDown={e => {
            if (e.detail > 1) {
              e.preventDefault();
            }
            setIsMouseDown(true);
          }}
          onMouseUp={e => {
            if (window.getSelection()?.toString() && selectedPath) {
              onSelectPath(selectedPath);
            }

            setIsSelectionInSubject(true);
            setSelectedText(window.getSelection()?.toString());
            setIsMouseDown(false);
          }}
        >
          {selectedParsedResult?.subject}
          <div
            style={{
              position: "absolute",
              pointerEvents: isMouseDown ? "none" : "all",
              top: 0,
              left: 0,
              color: "transparent",
              width: "100%",
            }}
          >
            {subjectSegments?.map((segment, i) => (
              <SpanWithHoverTooltip
                key={i}
                style={{
                  outline: "1px solid transparent",
                  backgroundColor: !isNil(segment?.meta?.startInd)
                    ? `${strToColor(segment?.meta?.fieldName)}55`
                    : "transparent",
                }}
                isTooltipDisabled={isNil(segment?.meta?.fieldName)}
                tooltip={
                  <LabelTip>
                    <StyledCrossIcon
                      onClick={() => doRemoveMeta(segment?.meta?.fieldName)}
                    />
                    {segment?.meta?.fieldName}
                  </LabelTip>
                }
              >
                {segment?.text}
              </SpanWithHoverTooltip>
            ))}
          </div>
        </EmailSubject>
        <StyledSelect
          style={{ width: "100%" }}
          value={selectedEmailId}
          onChange={e => setSelectedEmailId(e.target.value)}
        >
          {record?.parsedResultIds?.map(id => {
            const parsedResult = parsedResults?.find(
              parsedResult => parsedResult?._id === id
            );
            const { date, subject, from } = parsedResult || {};

            return (
              <option value={id} key={id} style={{ padding: "2px" }}>
                {subject}
              </option>
            );
          })}
        </StyledSelect>

        <SearchableSelect
          placeholder={
            selectedText ? "-- Choose label --" : "-- Select text --"
          }
          style={{
            zIndex: 1,
            justifySelf: "end",
            opacity: selectedText ? 1 : 0.5,
            pointerEvents: selectedText ? "all" : "none",
            marginBottom: "8px",
          }}
          value={selectedPath}
          onChange={e => onSelectPath(e.target.value)}
          options={selectOptions}
        />

        <EmailHtml
          ref={emailBodyRef}
          onMouseDown={e => {
            if (e.detail > 1) {
              e.preventDefault();
            }
            setIsMouseDown(true);
          }}
          onMouseUp={() => {
            if (window.getSelection()?.toString() && selectedPath) {
              onSelectPath(selectedPath);
            }

            setIsSelectionInSubject(false);
            setSelectedText(window.getSelection()?.toString());
            setIsMouseDown(false);
          }}
        >
          {selectedParsedResult?.text}
          <div
            style={{
              position: "absolute",
              pointerEvents: isMouseDown ? "none" : "all",
              top: 0,
              left: 0,
              color: "transparent",
              width: "100%",
            }}
          >
            {parsedResultsSegments?.map((segment, i) => (
              <SpanWithHoverTooltip
                key={i}
                style={{
                  outline: "1px solid transparent",
                  backgroundColor: !isNil(segment?.meta?.startInd)
                    ? `${strToColor(segment?.meta?.fieldName)}55`
                    : "transparent",
                }}
                isTooltipDisabled={
                  isNil(segment?.meta?.fieldName) ||
                  segment?.meta?.fieldName === "TBD"
                }
                tooltip={
                  <LabelTip>
                    <StyledCrossIcon
                      onClick={() => doRemoveMeta(segment?.meta?.fieldName)}
                    />
                    {segment?.meta?.fieldName}
                  </LabelTip>
                }
              >
                {segment?.text}
              </SpanWithHoverTooltip>
            ))}
          </div>
        </EmailHtml>
      </RightColumn>
    </Container>
  );
};

export default DealPage;
