import { cloneDeep, isNil, set, uniq } from "lodash";
import { useState, useRef, Fragment } from "react";
import styled from "styled-components";

import SpanWithHoverTooltip from "components/SpanWithHoverTooltip";
import { CrossIcon } from "components/ui/Icons";
import { strToColor } from "utils/common";
import {
  getSelectOptionsFromSchema,
  getTextSegments,
} from "components/DealDetailsModalTrigger";
import SearchableSelect from "components/SearchableSelect";
import { useEffect } from "react";
import { postFormatValue } from "api/services/dealService";

const StyledSelect = styled.select`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const EmailHtml = styled.div`
  position: relative;
  overflow: auto;
  height: calc(100% - 20px);
  width: 100%;
  white-space: pre-wrap;
  line-height: 1.5;
  color: ${props => props.theme.color.closest};

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

const EmailSubject = styled.div`
  font-weight: 600;
  position: relative;
  color: ${props => props.theme.color.primary};
  border-bottom: 2px solid ${props => props.theme.color.primary};
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const StyledCrossIcon = styled(CrossIcon)`
  fill: white;
  height: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const LabelTip = styled.div`
  padding: 2px 4px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
`;

const AnnotatedText = ({
  parsedResult = {},
  onChangeParsedResult = () => {},
  text = "",
  subject = "",
  schema = {},
  style = {},
}) => {
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isSelectionInSubject, setIsSelectionInSubject] = useState(false);

  const [parsedResultsSegments, setParsedResultsSegments] = useState([]);
  const [subjectSegments, setSubjectSegments] = useState([]);

  const emailBodyRef = useRef(null);

  useEffect(() => {
    let newParsedResultsSegments = getTextSegments(text, parsedResult);
    let newSubjectSegments = getTextSegments(
      subject,
      parsedResult,
      meta => meta?.inSubject,
      true
    );

    if (isSelectionInSubject) {
      newParsedResultsSegments = parsedResultsSegments?.filter(
        segment => segment?.meta?.fieldName !== "TBD"
      );
    } else {
      newSubjectSegments = subjectSegments?.filter(
        segment => segment?.meta?.fieldName !== "TBD"
      );
    }

    setParsedResultsSegments(newParsedResultsSegments);
    setSubjectSegments(newSubjectSegments);
  }, [selectedText, JSON.stringify(parsedResult), text, subject]);

  const onSelectPath = async newPath => {
    setSelectedPath(newPath);

    if (newPath === "-" || newPath === "") {
      return;
    }

    let tbdSegment = parsedResultsSegments?.find(
      segment => segment?.meta?.fieldName === "TBD"
    );
    if (isSelectionInSubject) {
      tbdSegment = subjectSegments?.find(
        segment => segment?.meta?.fieldName === "TBD"
      );
    }

    let { startInd, endInd, fieldValue } = tbdSegment?.meta || {};
    if (!fieldValue) {
      return;
    }

    const { data } = await postFormatValue(
      {},
      {
        text: fieldValue,
        field: newPath,
      }
    );
    fieldValue = data?.value || fieldValue;

    const newParsedResult = cloneDeep(parsedResult);
    set(newParsedResult, `${newPath}.meta`, {
      startInd,
      endInd,
      manuallyEnetered: true,
      inSubject: isSelectionInSubject,
    });
    set(newParsedResult, `${newPath}.value`, fieldValue);

    onChangeParsedResult(newParsedResult);
  };

  const doRemoveMeta = async fieldName => {
    const newParsedResult = cloneDeep(parsedResult);
    set(newParsedResult, `${fieldName}.meta`, {});
    set(newParsedResult, `${fieldName}.value`, null);
    onChangeParsedResult(newParsedResult);
  };

  const allGroups = uniq(
    schema?.fields?.map(field => field?._meta?.groupName) || []
  );
  const allFields = schema?.fields?.map(field => field.name);

  const emailBodyRect = emailBodyRef?.current?.getBoundingClientRect() || {};

  const selectOptions = getSelectOptionsFromSchema(schema);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        // overflow: "auto",
        whiteSpace: "pre-wrap",
        ...style,
      }}
    >
      <SearchableSelect
        placeholder={selectedText ? "-- Choose label --" : "-- Select text --"}
        style={{
          zIndex: 1,
          justifySelf: "end",
          opacity: selectedText ? 1 : 0.5,
          pointerEvents: selectedText ? "all" : "none",
          marginBottom: "4px",
        }}
        value={selectedPath}
        onChange={e => onSelectPath(e.target.value)}
        options={selectOptions}
      />
      {/* <StyledSelect
        style={{
          // position: "fixed",
          // left: emailBodyRect?.left + emailBodyRect?.width,
          // top: emailBodyRect?.top,
          // transform: "translate(-100%, -100%)",
          zIndex: 1,
          backgroundColor: "white",
          opacity: selectedText ? 1 : 0.5,
          pointerEvents: selectedText ? "all" : "none",
        }}
        value={selectedPath}
        onChange={e => {
          onSelectPath(e.target.value);
        }}
      >
        <option value="-">
          {selectedText ? "-- Choose label --" : "-- Select text below --"}
        </option>
        {allGroups?.map(groupName => (
          <optgroup key={groupName} label={groupName}>
            {allFields?.map(fieldName => {
              const fieldSchema = schema?.fields?.find(
                field => field.name === fieldName
              );

              if (fieldSchema?._meta?.groupName !== groupName) {
                return null;
              }

              if (fieldSchema?.type === "record") {
                return (
                  <Fragment key={fieldName}>
                    {fieldSchema?.fields?.map(subField => (
                      <option key={`${fieldName}.${subField?.name}`}>
                        {fieldName}.{subField?.name}
                      </option>
                    ))}
                  </Fragment>
                );
              }

              return <option key={fieldName}>{fieldName}</option>;
            })}
          </optgroup>
        ))}
      </StyledSelect> */}

      {subject && (
        <EmailSubject
          onMouseDown={e => {
            if (e.detail > 1) {
              e.preventDefault();
            }
            setIsMouseDown(true);
            setIsSelectionInSubject(true);
          }}
          onMouseUp={e => {
            if (window.getSelection()?.toString() && selectedPath) {
              onSelectPath(selectedPath);
            }

            setSelectedText(window.getSelection()?.toString());
            setIsMouseDown(false);
          }}
        >
          {subject}
          <div
            style={{
              position: "absolute",
              pointerEvents: isMouseDown ? "none" : "all",
              top: 0,
              left: 0,
              color: "transparent",
              width: "100%",
            }}
          >
            {subjectSegments?.map((segment, i) => (
              <SpanWithHoverTooltip
                key={i}
                style={{
                  outline: "1px solid transparent",
                  backgroundColor: !isNil(segment?.meta?.startInd)
                    ? `${strToColor(segment?.meta?.fieldName)}55`
                    : "transparent",
                }}
                isTooltipDisabled={isNil(segment?.meta?.fieldName)}
                tooltip={
                  <LabelTip>
                    <StyledCrossIcon
                      onClick={() => doRemoveMeta(segment?.meta?.fieldName)}
                    />
                    {segment?.meta?.fieldName}
                  </LabelTip>
                }
              >
                {segment?.text}
              </SpanWithHoverTooltip>
            ))}
          </div>
        </EmailSubject>
      )}

      <EmailHtml
        ref={emailBodyRef}
        onClick={() => {
          setSelectedPath("");
        }}
        onMouseDown={e => {
          if (e.detail > 1) {
            e.preventDefault();
          }
          setIsMouseDown(true);
        }}
        onMouseUp={e => {
          if (window.getSelection()?.toString() && selectedPath) {
            onSelectPath(selectedPath);
          }

          setIsSelectionInSubject(false);
          setSelectedText(window.getSelection()?.toString());
          setIsMouseDown(false);
        }}
      >
        {text}
        <div
          style={{
            position: "absolute",
            pointerEvents: isMouseDown ? "none" : "all",
            top: 0,
            left: 0,
            color: "transparent",
            width: "100%",
          }}
        >
          {parsedResultsSegments?.map((segment, i) => (
            <SpanWithHoverTooltip
              key={i}
              style={{
                outline: "1px solid transparent",
                backgroundColor: !isNil(segment?.meta?.startInd)
                  ? `${strToColor(segment?.meta?.fieldName)}55`
                  : "transparent",
              }}
              isTooltipDisabled={
                isNil(segment?.meta?.fieldName) ||
                segment?.meta?.fieldName === "TBD"
              }
              tooltip={
                <LabelTip>
                  <StyledCrossIcon
                    onClick={() => doRemoveMeta(segment?.meta?.fieldName)}
                  />
                  {segment?.meta?.fieldName}
                </LabelTip>
              }
            >
              {segment?.text}
            </SpanWithHoverTooltip>
          ))}
        </div>
      </EmailHtml>
    </div>
  );
};

export default AnnotatedText;
