import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { getFile, getFileResults } from "api/services/filesService";
import { CenteredWithSideNavLayout, Gap } from "components/Layout";
import Table from "components/widgets/Table";
import TextResult from "components/widgets/TextResult";
import { BigTitle } from "components/ui/Text";
import LoadingSpinner from "components/ui/LoadingSpinner";

const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
`;

const ResultColumn = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  align-content: start;
`;

const Img = styled.img`
  width: 100%;
  border: 1px solid ${props => props.theme.color.closer0};
`;

const FilePage = () => {
  const { fileId } = useParams();

  const [file, setFile] = useState(null);
  const [results, setResults] = useState(null);

  useEffect(() => {
    doFetchFile();
    doFetchFileResults();
  }, [fileId]);

  const doFetchFile = async () => {
    const { data, error } = await getFile(fileId);
    if (error) {
      alert(JSON.stringify(error));
      return;
    }
    setFile(data);
  };

  const doFetchFileResults = async () => {
    const { data, error } = await getFileResults(fileId);
    if (error) {
      alert(JSON.stringify(error));
      return;
    }
    setResults(data);
  };

  return (
    <CenteredWithSideNavLayout>
      <BigTitle>{file?.fileName || ""}</BigTitle>
      <Gap />
      {!results && <LoadingSpinner />}
      <ResultsContainer>
        {results?.map((result, resInd) => (
          <ResultColumn key={resInd}>
            {result?.image && <Img src={`data:image/png;base64, ${result?.image}`} alt="scanned-img" />}
            {result?.output?.map((outputItem, i) => {
              if (outputItem?.type === "table") {
                return <Table key={`${resInd}-${i}`} name={outputItem?.tableName} rows={outputItem?.tablePreview} />;
              }
              if (outputItem?.type === "text") {
                return <TextResult textItem={outputItem} key={`${resInd}-${i}`} />;
              }
              return <div key={`${resInd}-${i}`}>{JSON.stringify(outputItem)}</div>;
            })}
          </ResultColumn>
        ))}
      </ResultsContainer>
    </CenteredWithSideNavLayout>
  );
};

export default FilePage;
