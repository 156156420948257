import * as backend from "api/backend/dealEndpoints";
import SCHEMA from "api/services/schema.json";
import { sleep } from "utils/common";

export const REQUIRED_FIELDS = [
  "Ticker",
  "Currency",
  "Series",
  "Min Piece",
  "Min Increment",
  // "Min Denomination",
  // "Min Denomination Increment",
  // "Status",
];

export const getMissingFields = (record, schema = {}) => {
  const missingFields = [];
  REQUIRED_FIELDS.forEach(fieldName => {
    const recordValue = record?.extractedFields?.[fieldName]?.value;

    if (!recordValue) {
      missingFields.push(fieldName);
      return;
    }

    const fieldSchema = schema?.fields?.find(f => f?.name === fieldName);
    const { type, symbols } = fieldSchema || {};
    if (type === "enum" && !symbols?.includes(recordValue)) {
      missingFields.push(fieldName);
    }
  });

  return missingFields;
};

export const getDeal = async (id, params = {}) => {
  const { data, error } = await backend.getDeal(id, params);
  return { data, error };
};

export const getDeals = async (params = {}) => {
  const { data, error } = await backend.getDeals(params);
  return { data, error };
};

export const patchDeal = async (id, params = {}, body = {}) => {
  const { data, error } = await backend.patchDeal(id, params, body);
  return { data, error };
};

export const getEmail = async (id, params = {}) => {
  const { data, error } = await backend.getEmail(id, params);
  return { data, error };
};

export const getEmails = async (params = {}) => {
  const { data, error } = await backend.getEmails(params);

  if (params?.emailIds) {
    return {
      data: data?.filter(email => params?.emailIds?.includes(email?._id)),
      error,
    };
  }
  return { data, error };
};

export const patchEmail = async (id, params = {}, body = {}) => {
  const { data, error } = await backend.patchEmail(id, params, body);
  return { data, error };
};

export const getExtractionSchema = async (params = {}) => {
  // return { data: SCHEMA };

  const { data, error } = await backend.getExtractionSchema(params);
  return { data, error };
};

export const postAdhocIngestions = async (params = {}, body = {}) => {
  const { data, error } = await backend.postAdhocIngestions(params, body);
  return { data, error };
};

export const getParsedResults = async (params = {}) => {
  const { data, error } = await backend.getParsedResults(params);

  if (params?.parsedResultIds) {
    return {
      data: data?.filter(result =>
        params?.parsedResultIds?.includes(result?._id)
      ),
      error,
    };
  }
  return { data, error };
};

export const getParsedResult = async (id, params = {}) => {
  const { data, error } = await backend.getParsedResult(id, params);
  return { data, error };
};

export const patchParsedResult = async (id, params = {}, body = {}) => {
  const { data, error } = await backend.patchParsedResult(id, params, body);
  return { data, error };
};

export const getSummaryStats = async (params = {}) => {
  const { data, error } = await backend.getSummaryStats(params);
  return { data, error };
};

export const postClear = async (params = {}, body = {}) => {
  const { data, error } = await backend.postClear(params, body);
  return { data, error };
};

export const postParsedResultsCreateSibling = async (
  id,
  params = {},
  body = {}
) => {
  const { data, error } = await backend.postParsedResultsCreateSibling(
    id,
    params,
    body
  );
  return { data, error };
};

export const postEmailsMarkNoDeals = async (
  emailId = "",
  params = {},
  body = {}
) => {
  const { data, error } = await backend.postEmailsMarkNoDeals(
    emailId,
    params,
    body
  );
  return { data, error };
};

export const deleteParsedResults = async (id, params = {}) => {
  const { data, error } = await backend.deleteParsedResults(id, params);
  return { data, error };
};

export const getDealsSearch = async (params = {}) => {
  const { data, error } = await backend.getDealsSearch(params);
  return { data, error };
};

export const postFormatValue = async (params = {}, body = {}) => {
  const { data, error } = await backend.postFormatValue(params, body);
  return { data, error };
};

const RES = {
  columns: [
    {
      name: "State",
    },
    {
      name: "Loan Amount",
    },
    {
      name: "Number of employees",
    },
  ],
  records: [
    {
      State: {
        value: "Texas",
      },
      "Loan Amount": {
        value: 0,
      },
      "Number of employees": {
        value: 2,
      },
    },
    {
      State: {
        value: "Washington",
      },
      "Loan Amount": {
        value: 14,
      },
    },
  ],
};

export const postExecuteNlc = async (
  params = {},
  body = {},
  abortController
) => {
  const { data, error } = await backend.postExecuteNlc(
    params,
    body,
    abortController
  );
  return { data, error };
};

export const getRecommendedNlc = async (params = {}) => {
  const { data, error } = await backend.getRecommendedNlc(params);
  return { data, error };
};

export const getConfig = async (params = {}) => {
  const { data, error } = await backend.getConfig(params);
  return { data, error };
};

export const patchConfig = async (params = {}, body = {}) => {
  const { data, error } = await backend.patchConfig(params, body);
  return { data, error };
};
