import styled from "styled-components";

import { useState } from "react";
import Modal from "components/ui/Modal";

const ModalContent = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ModalText = styled.div`
  font-size: 18px;
  max-width: 400px;
  line-height: 1.5;
  text-align: center;
`;

const BottomButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const BottomButton = styled.button`
  padding: 2px 8px;
`;

const TriggerContainer = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.2;"};
`;

const ConfirmationModalTrigger = ({
  icon = <div>modal</div>,
  message = "Are you sure you want to delete?",
  onClickYes = () => {},
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TriggerContainer isDisabled={isDisabled} onClick={() => setIsOpen(true)}>
        {icon}
      </TriggerContainer>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isDisabled}>
          <ModalText dangerouslySetInnerHTML={{ __html: message }} />
          <BottomButtons>
            <BottomButton
              onClick={async () => {
                await onClickYes();
                setIsOpen(false);
              }}
            >
              Yes
            </BottomButton>
            <BottomButton onClick={() => setIsOpen(false)}>Cancel</BottomButton>
          </BottomButtons>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmationModalTrigger;
