import { useState } from "react";
import styled from "styled-components";

import useSearchParamsState from "hooks/useSearchParamState";

import SearchableSelectOptions from "components/SearchableSelectOptions";
import SearchableSelect from "components/SearchableSelect";

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

const SideBar = styled.div`
  border-right: 1px solid #eaeaea;
  height: 100vh;
`;

const ComponentArea = styled.div`
  width: 800px;
  height: 700px;
  resize: both;
  overflow: scroll;
  border: 1px dashed lightgrey;
  border-left: none;
  border-top: none;
`;

const StoryNavItem = styled.div`
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? "#eaeaea" : "transparent")};
`;

const SearchableSelectOptionsStory = () => {
  const [value, setValue] = useState("");

  return (
    <>
      <div>value: {value}</div>
      <SearchableSelectOptions
        value={value}
        onChange={e => {
          setValue(e.target.value);
        }}
      >
        <optgroup label="sayings">
          <option value="1">hello</option>
          <option value="2">goodbye</option>
          <>
            <option value="3">something</option>
            <option value="4">else</option>
          </>
        </optgroup>
      </SearchableSelectOptions>
    </>
  );
};

const SearchableSelectStory = () => {
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");

  return (
    <>
      <div style={{ border: "1px dashed #eaeaea", marginBottom: "20px" }}>
        <div>value: {value}</div>
        <SearchableSelect
          value={value}
          onChange={e => {
            setValue(e.target.value);
          }}
          options={[
            { name: "hello", value: "1", group: "sayings" },
            { name: "goodbye", value: "2", group: "sayings" },
          ]}
        />
      </div>

      <div style={{ border: "1px dashed #eaeaea", marginBottom: "20px" }}>
        <div>value2: {value2}</div>
        <SearchableSelect
          value={value2}
          onChange={e => {
            setValue2(e.target.value);
          }}
          options={[
            { name: "hello", value: "1" },
            { name: "goodbye", value: "2" },
          ]}
        />
      </div>
    </>
  );
};

const STORIES = [
  {
    name: "Title",
    component: <h1>whatever</h1>,
  },
  {
    name: "SearchableSelectOptions",
    component: <SearchableSelectOptionsStory />,
  },
  {
    name: "SearchableSelect",
    component: <SearchableSelectStory />,
  },
];

const LibraryPage = () => {
  const [selectedIndex, setSelectedIndex] = useSearchParamsState({
    paramName: "selectedIndex",
    initialValue: 0,
  });

  return (
    <Container>
      <SideBar>
        {STORIES?.map((story, i) => (
          <StoryNavItem
            key={i}
            isSelected={selectedIndex === i}
            onClick={() => setSelectedIndex(i)}
          >
            {story?.name}
          </StoryNavItem>
        ))}
      </SideBar>
      <ComponentArea>{STORIES[selectedIndex].component}</ComponentArea>
    </Container>
  );
};

export default LibraryPage;
