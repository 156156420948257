import { Routes, Route } from "react-router-dom";

import DashboardPage from "pages";
import FilePage from "pages/file/:fileId";
import LibraryPage from "pages/library";
import DashboardPageNew from "pages/dashboard";
import LoginPage from "pages/login";
import DealPage from "pages/deals/:dealId";
import MissingPage from "pages/missing";
import ParserPage from "pages/parser";
import ArchivePage from "pages/archive";
import AllRecordsPage from "pages/all-deals";
import EmailPage from "pages/emails/:emailId";
import ChatPage from "pages/chat";
import SettingsPage from "pages/settings";

const PageComponentOnRoute = () => (
  <Routes>
    <Route path={"/chat"} element={<ChatPage />} />
    <Route path={"/dashboard"} element={<DashboardPageNew />} />
    <Route path={"/"} element={<DashboardPage />} />
    <Route path={"/file/:fileId"} element={<FilePage />} />
    <Route path={"/library"} element={<LibraryPage />} />
    <Route path={"/login"} element={<LoginPage />} />
    <Route path={"/deals/:dealId"} element={<DealPage />} />
    <Route path={"/missing"} element={<MissingPage />} />
    <Route path={"/all"} element={<AllRecordsPage />} />
    <Route path={"/parser"} element={<ParserPage />} />
    <Route path={"/archive"} element={<ArchivePage />} />
    <Route path={"/emails/:emailId"} element={<EmailPage />} />
    <Route path={"/settings"} element={<SettingsPage />} />
  </Routes>
);

export default PageComponentOnRoute;
