import { invert } from "lodash";
import { useRef, useEffect, useState, Children } from "react";
import styled from "styled-components";

const Option = styled.div`
  margin-left: 8px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  padding: 2px 8px;
`;

const Popover = styled.div`
  z-index: 1;
  box-shadow: ${props => props.theme.shadow};
  background-color: ${props => props.theme.color.furthest};
  max-height: 400px;
  overflow: auto;
`;

// - use library page to develop a searchable tagging solution
// - figure out issue when options are inside a Fragment
const SearchableSelectOptions = ({
  children,
  style = {},
  value = "",
  onChange = () => {},
}) => {
  const [query, setQuery] = useState("");
  const [labelToId, setLabelToId] = useState({});
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const idToLabel = invert(labelToId);
    const newQuery = idToLabel[value] || "";
    setQuery(newQuery);
  }, [value, labelToId]);

  useEffect(() => {
    const newIdToLabel = {};

    Children.forEach(children, child => {
      if (!child) {
        return;
      }

      if (child.type === "optgroup") {
        Children.forEach(child.props.children, option => {
          if (!option) {
            return;
          }

          newIdToLabel[option.props.value || option.props.children] =
            option.props.children;
        });
      } else if (child.type === "option") {
        newIdToLabel[child.props.value || child.props.children] =
          child.props.children;
      }
    });

    setLabelToId(invert(newIdToLabel));
  }, [children]);

  const inputRect = ref.current?.getBoundingClientRect();

  return (
    <>
      <input
        style={style}
        ref={ref}
        onClick={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        onFocus={e => {
          e.stopPropagation();
          e.preventDefault();
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          if (query === "") {
            setQuery("");
            onChange({ target: { value: "" } });
          }
        }}
        value={query}
        onChange={e => setQuery(e.target.value)}
        placeholder="Choose label"
      />
      <Popover
        style={{
          position: "fixed",
          visibility: isFocused ? "visible" : "hidden",
          top: `${inputRect?.bottom}px`,
          left: `${inputRect?.left}px`,
          minWidth: `${inputRect?.width}px`,
        }}
      >
        {Children.map(children, child => {
          if (child.type === "optgroup") {
            const options = child?.props?.children?.filter(option =>
              option?.props?.children?.includes(query)
            );

            if (options?.length === 0) {
              return null;
            }

            return (
              <div>
                <div
                  style={{
                    fontWeight: 600,
                    padding: "2px",
                  }}
                >
                  {child?.props?.label}
                </div>
                {options?.map(option => (
                  <Option
                    onMouseDown={() => {
                      setQuery(option?.props?.children);
                      onChange({
                        target: { value: labelToId[option?.props?.children] },
                      });
                    }}
                  >
                    {option}
                  </Option>
                ))}
              </div>
            );
          }

          return <div>{child}</div>;
        })}
      </Popover>
    </>
  );
};

export default SearchableSelectOptions;
