import { useState, useEffect } from "react";

import { getTask } from "api/services/queueService";

const usePollTask = taskId => {
  const [task, setTask] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (!taskId) {
      setTask(null);
      return;
    }

    const newIntervalId = setInterval(async () => {
      const { data } = await getTask(taskId);
      setTask(data);
    }, 2000);
    setIntervalId(newIntervalId);

    return () => clearInterval(newIntervalId);
  }, [taskId]);

  useEffect(() => {
    if (task?.status === "DONE" || task?.progress === 100) {
      clearInterval(intervalId);
    }
  }, [task?.status, task?.progress]);

  return task;
};

export default usePollTask;
