import { groupBy } from "lodash";
import { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const Option = styled.div`
  margin-left: 8px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  padding: 2px 8px;
`;

const Popover = styled.div`
  z-index: 1;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.color.furthest};
  max-height: 400px;
  overflow: auto;
  color: white;
  font-size: 11px;
  padding: 8px 0;
`;

const StyledInput = styled.input`
  padding: 0 4px;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  background-color: ${props => props.theme.color.closer0};
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  border-radius: 2px;
  color-scheme: dark;
  width: 100%;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const SearchableSelect = ({
  placeholder = "",
  style = {},
  value = "",
  onChange = ({ target: { value } }) => {},
  options = [{ name: "hello", value: "1", group: "sayings" }],
}) => {
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const matchingOption = options.find(option => option.value === value);
    const newQuery = matchingOption?.name || "";
    setQuery(newQuery);
  }, [value, JSON.stringify(options)]);

  const inputRect = ref.current?.getBoundingClientRect();

  const optionsByGroup = groupBy(options, "group");
  const groupNames = Object.keys(optionsByGroup);

  return (
    <>
      <StyledInput
        style={style}
        ref={ref}
        onClick={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        onFocus={e => {
          e.stopPropagation();
          e.preventDefault();
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          if (query === "") {
            setQuery("");
            onChange({ target: { value: "" } });
          }
        }}
        value={query}
        onChange={e => setQuery(e.target.value)}
        placeholder={placeholder}
      />
      <Popover
        style={{
          position: "fixed",
          visibility: isFocused ? "visible" : "hidden",
          top: `${inputRect?.bottom}px`,
          left: `${inputRect?.left}px`,
          minWidth: `${inputRect?.width}px`,
        }}
      >
        {groupNames?.map(groupName => {
          const groupOptions = optionsByGroup[groupName]?.filter(option =>
            option?.name?.toLowerCase()?.includes(query?.toLowerCase())
          );

          if (!groupOptions?.length) {
            return null;
          }

          const groupOptionsContent = groupOptions?.map(option => (
            <Option
              key={option?.value}
              onMouseDown={() => {
                setQuery("");
                onChange({
                  target: { value: option?.value },
                });
              }}
            >
              {option?.name}
            </Option>
          ));

          if (groupName === "undefined") {
            return groupOptionsContent;
          }

          return (
            <div key={groupName}>
              <div
                style={{
                  fontWeight: 600,
                  padding: "2px",
                  paddingLeft: "4px",
                }}
              >
                {groupName}
              </div>
              {groupOptionsContent}
            </div>
          );
        })}
      </Popover>
    </>
  );
};

export default SearchableSelect;
