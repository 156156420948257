import { apiPost } from "api/api-http-methods";
import { errorTypes } from "api/error-handling";

export const getLoginTokens = async (userName, password) => {
  const { data, error } = await apiPost(
    "/tpicap-deal/authentication-service/login",
    {},
    {
      userName,
      password,
    }
  );
  if (error) {
    return { data, error: { type: errorTypes.LOGIN_ERROR } };
  }
  return { data, error: null };
};
