import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  deleteParsedResults,
  getEmail,
  getExtractionSchema,
  getParsedResults,
  patchParsedResult,
  postParsedResultsCreateSibling,
} from "api/services/dealService";
import SideNav from "components/SideNav";
import DealFieldsEditor from "components/DealFieldsEditor";
import AnnotatedText from "components/AnnotatedText";
import { PlusIcon, TrashIcon } from "components/ui/Icons";
import { cloneDeep, last, set } from "lodash";

const Container = styled.div`
  display: grid;
  grid-template-columns: 60px auto 400px;
  background: linear-gradient(180deg, #000000 0%, #000000 100%);
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StyledTrashIcon = styled(TrashIcon)`
  fill: ${props => props.theme.color.closest};
  cursor: pointer;
  height: 12px;
  opacity: 0;

  :hover {
    opacity: 0.6;
  }
`;

const Tab = styled.div`
  padding: 8px 12px;
  font-weight: 500;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer1_5};
  border-bottom: 2px solid
    ${props =>
      props.isSelected
        ? props.theme.color.primary
        : props.theme.color.closer1_5};
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;

  :hover {
    ${StyledTrashIcon} {
      opacity: 1;
      :hover {
        opacity: 0.6;
      }
    }
  }
`;

const RightColumn = styled.div`
  padding: 10px;
  display: grid;
  align-content: start;
  background-color: #24252a;
  grid-template-rows: auto auto auto 1fr;
  height: 100vh;
  position: sticky;
  top: 0;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin: 8px;
  fill: ${props => props.theme.color.closest};
  cursor: pointer;
  height: 12px;
`;

const EmailPage = () => {
  const { emailId } = useParams();
  const [schema, setSchema] = useState({});
  const [emailRecord, setEmailRecord] = useState({});
  const [parsedResults, setParsedResults] = useState([]);
  const [selectedParsedResultId, setSelectedParsedResultId] = useState(null);
  const [selectedDealId, setSelectedDealId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [layout, setLayout] = useState(undefined);

  useEffect(() => {
    doPopulateSchema();
    doPopulateEmailRecord();
  }, [emailId]);

  useEffect(() => {
    if (!emailRecord?.parsedResultIds?.length) {
      return;
    }
    doPopulateParsedResults(emailRecord?.parsedResultIds);
  }, [emailRecord?.parsedResultIds]);

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  const doPopulateEmailRecord = async () => {
    const { data } = await getEmail(emailId);
    setEmailRecord(data);
  };

  const doAddParsedResult = async () => {
    await postParsedResultsCreateSibling(selectedParsedResultId);
    doPopulateEmailRecord();
  };

  const doDeleteParsedResult = async id => {
    // const newParsedResults = parsedResults.filter(
    //   parsedResult => parsedResult?._id !== id
    // );
    // setParsedResults(newParsedResults);
    // setSelectedParsedResultId(last(newParsedResults)?._id);
    await deleteParsedResults(id);
    doPopulateEmailRecord();
  };

  const doPopulateParsedResults = async ids => {
    const { data } = await getParsedResults({ ids });
    setParsedResults(data);
    setSelectedParsedResultId(data?.[0]?._id);
  };

  const updateParsedResult = (id, newParsedResult) => {
    if (!parsedResults?.length) {
      setParsedResults([{ _id: id, ...newParsedResult }]);
      return;
    }

    const newParsedResults = parsedResults.map(parsedResult =>
      parsedResult?._id === id
        ? { ...parsedResult, ...newParsedResult }
        : parsedResult
    );
    setParsedResults(newParsedResults);
  };

  const selectedParsedResult = parsedResults.find(
    parsedResult => parsedResult?._id === selectedParsedResultId
  );

  const doPatchSelectedParsedResult = async () => {
    const newExtractedFields = cloneDeep(selectedParsedResult?.extractedFields);
    if (newExtractedFields?.name?.value?.includes("[")) {
      delete newExtractedFields?.name;
    }

    setIsDisabled(true);
    const { data } = await patchParsedResult(
      selectedParsedResultId,
      {},
      {
        extractedFields: newExtractedFields,
      }
    );
    updateParsedResult(selectedParsedResultId, data);
    setIsDisabled(false);
  };

  return (
    <Container>
      <div style={{ width: "60px" }} />
      <SideNav />
      <div
        style={{
          height: "100vh",
          overflow: "auto",
          paddingLeft: "10px",
          paddingRight: "10px",
          opacity: isDisabled ? 0.5 : 1,
        }}
      >
        <Tabs>
          {!parsedResults?.length && <Tab isSelected>Tranche 1</Tab>}
          {parsedResults.map((parsedResult, i) => (
            <Tab
              key={parsedResult?._id}
              isSelected={parsedResult?._id === selectedParsedResultId}
              onClick={() => setSelectedParsedResultId(parsedResult?._id)}
            >
              Tranche {i + 1}
              <StyledTrashIcon
                style={{ display: i === 0 ? "none" : "block" }}
                onClick={e => {
                  e.stopPropagation();
                  doDeleteParsedResult(parsedResult?._id);
                }}
              />
            </Tab>
          ))}
          <StyledPlusIcon onClick={doAddParsedResult} />
        </Tabs>
        <DealFieldsEditor
          dealId="abc"
          layout={layout}
          onNewLayout={newLayout => setLayout(newLayout)}
          fields={selectedParsedResult?.extractedFields}
          schema={schema}
          onChange={extractedFields =>
            updateParsedResult(selectedParsedResultId, {
              extractedFields,
            })
          }
          onClickSave={doPatchSelectedParsedResult}
          isJsonBtnVisible
        />
      </div>
      <RightColumn>
        <AnnotatedText
          style={{
            height: "600px",
          }}
          parsedResult={selectedParsedResult?.extractedFields}
          schema={schema}
          text={selectedParsedResult?.text}
          subject={selectedParsedResult?.subject}
          onChangeParsedResult={extractedFields => {
            updateParsedResult(selectedParsedResultId, {
              extractedFields,
            });
          }}
        />
      </RightColumn>
    </Container>
  );
};

export default EmailPage;
