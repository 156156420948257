import ConfigViewer from "components/ConfigViewer";
import { useState } from "react";
import { useConfig } from "utils/config-utils";

const CONFIG = {
  title: "Dashboard",
  deals: [],
  dealItems: [],
  stages: ["Books Open", "Deal Updates", "Deal Launched"],
  filter: "",
  filteredStages: config =>
    config.stages.filter(stage => stage.includes(config.filter)),
  groups: [],
};

const DashboardPageNew = () => {
  const [config, setConfig] = useConfig(CONFIG);

  return (
    <>
      <ConfigViewer
        topLevelConfig={config}
        setVars={setConfig}
        config={config}
      />
    </>
  );
};

export default DashboardPageNew;
