import { useState, useEffect } from "react";
import styled from "styled-components";

import { getExtractionSchema } from "api/services/dealService";
import DealRecordCard from "components/DealRecordCard";

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-content: start;
  padding-top: 10px;
`;

export const COLUMN_TO_COLOR = {
  "Books Open": "#4774c5",
  "Deal Updates": "#ed7f34",
  "Deal Launched": "#a6a6a6",
  "Books Subject": "#ffbd01",
  "Allocations Released": "#5e9dd5",
  "Deal Priced": "#6fac46",
};

const RecordsCards = ({
  records = [],
  onClickSaveRecord = (id, newFields) => {},
}) => {
  const [schema, setSchema] = useState({});

  useEffect(() => {
    doPopulateSchema();
  }, []);

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  return (
    <Container>
      {records?.map((record, i) => (
        <DealRecordCard
          key={record?._id}
          schema={schema}
          records={[record]}
          onClickSaveRecord={onClickSaveRecord}
        />
      ))}
    </Container>
  );
};

export default RecordsCards;
