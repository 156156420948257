import { cloneDeep } from "lodash";
import styled from "styled-components";

const Container = styled.div``;

const Select = styled.select`
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  resize: none;
  background-color: transparent;
  color: white;
  max-width: 130px;
  font-size: 11px;
  padding: 2px 0;
  color-scheme: dark;
  background-color: #181a1f;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const Input = styled.input`
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  background-color: transparent;
  color: white;
  max-width: 80px;
  font-size: 11px;
  color-scheme: dark;
  padding: 2px 0;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const AVRO_TO_INPUT_TYPE = {
  string: "text",
  int: "number",
  double: "number",
  enum: "select",
  record: "multilevel",
  boolean: "checkbox",
  array: "array",
};

const getInputType = field => {
  if (field?._meta?.format === "timestamp") {
    return "date";
  }

  if (field?._meta?.format === "textarea") {
    return "textarea";
  }

  return AVRO_TO_INPUT_TYPE[field?.type] || "text";
};

const MultilevelSelect = ({
  style = {},
  fields = [],
  value = {},
  onChange = newValue => {},
}) => {
  return (
    <Container style={style}>
      {fields?.map((field, i) => {
        const onChangeFieldValue = e => {
          const newValue = cloneDeep(value);
          if (!newValue[field?.name]) {
            newValue[field?.name] = {
              value: "",
              meta: {},
            };
          }
          newValue[field?.name].value = e.target.value;
          newValue[field?.name].meta.manuallyEntered = true;
          newValue[field?.name].meta.defaultValue = false;

          onChange(newValue);
        };

        const onChangeCheckboxFieldValue = e => {
          const newValue = cloneDeep(value);
          if (!newValue[field?.name]) {
            newValue[field?.name] = {
              value: false,
              meta: {},
            };
          }
          newValue[field?.name].value = e.target.checked;
          newValue[field?.name].meta.manuallyEntered = true;

          onChange(newValue);
        };

        const type = getInputType(field) || "text";
        const defaultValue = field?.default || "";

        let inputComponent = (
          <Select
            value={value?.[field?.name]?.value || defaultValue}
            onChange={onChangeFieldValue}
          >
            <option value=""></option>
            {field?.symbols?.map(option => (
              <option key={option}>{option}</option>
            ))}
          </Select>
        );

        if (["date", "text", "number"]?.includes(type)) {
          inputComponent = (
            <Input
              type={type}
              value={value?.[field?.name]?.value || defaultValue}
              onChange={onChangeFieldValue}
            />
          );
        }

        if (field?.type === "boolean") {
          inputComponent = (
            <Input
              style={{ width: "max-content" }}
              type="checkbox"
              checked={value?.[field?.name]?.value || defaultValue}
              onChange={onChangeCheckboxFieldValue}
            />
          );
        }

        return (
          <label
            style={{
              display: "grid",
              justifyContent: "start",
              gridTemplateColumns: "1fr 1fr",
              gap: "4px",
              paddingBottom: "4px",
            }}
          >
            <div
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {field?.name}
            </div>
            {inputComponent}
          </label>
        );
      })}
    </Container>
  );
};

export default MultilevelSelect;
