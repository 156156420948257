import { groupBy, last } from "lodash";
import { useState } from "react";
import styled from "styled-components";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DealRecordCard from "components/DealRecordCard";
import { RightCornerDots } from "components/ui/Icons";

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(276px, auto));
  gap: 10px;
  overflow: auto;
  height: 100%;
  justify-content: start;
`;

const StyledRightCornerDots = styled(RightCornerDots)`
  position: absolute;
  top: 4px;
  right: 4px;

  height: 62px;
  fill: #2f3135;
`;

const StageTitle = styled.div`
  font-size: 17px;
  padding: 8px 0;
  padding-bottom: 0px;
  font-weight: 600;
  position: relative;
`;

const CardStackContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: max-content;
  background-color: ${props => props.theme.color.closer0};

  :hover {
    outline: 1px solid ${props => props.theme.color.primary};
  }
`;

const ExpandContainer = styled.div`
  background-color: ${props => props.theme.color.closer0};
  margin-top: -4px;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  padding-right: 4px;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12);

  svg {
    height: 18px;
    width: 18px;
  }

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const CardStack = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CardStackContainer isOpen={isOpen}>
      {isOpen ? children : last(children)}

      <ExpandContainer
        onClick={() => setIsOpen(!isOpen)}
        style={{
          display: children?.length > 1 ? "flex" : "none",
        }}
      >
        <span
          style={{
            fontSize: "10px",
            fontWeight: 900,
            color: "#888",
          }}
        >
          {children?.length} tranches
        </span>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ExpandContainer>
    </CardStackContainer>
  );
};

const StageColumn = styled.div`
  position: relative;
  /* height: calc(100vh - 20px); */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 8px;
  padding-bottom: 20px;
  border-radius: 4px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const COLUMN_TO_COLOR = {
  "Books Open": "#4ECBC6",
  "Deal Updates": "#ed7f34",
  "Books Closed": "#FF437B",
  "Books Subject": "#FCDA41",
  Allocated: "#82287B",
  Priced: "#98D757",
};

const RecordsKanban = ({
  records = [],
  onClickSaveRecord = (id, newFields) => {},
  selectedField,
  schema,
}) => {
  const columnNames =
    schema?.fields?.find(field => field.name === selectedField)?.symbols || [];

  return (
    <Columns>
      {columnNames?.map((stageValue, i) => {
        let stageRecords = records?.filter(
          record =>
            record?.extractedFields?.[selectedField]?.value === stageValue
        );

        const recordsByName = groupBy(stageRecords, r => {
          // const name = r?.extractedFields?.name?.value;
          // const maturity = r?.extractedFields?.Maturity?.value;
          // const series = r?.extractedFields?.Series?.value;
          // const nameWithoutMaturityAndSeries = name
          //   ?.replaceAll(maturity, "")
          //   ?.replaceAll(series, "")
          //   ?.trim();

          // return nameWithoutMaturityAndSeries;
          const issuerName = r?.extractedFields?.["Issuer Name"]?.value;
          return issuerName;
        });
        const dealNames = Object.keys(recordsByName);

        return (
          <StageColumn
            key={stageValue}
            style={{
              background: `linear-gradient(180deg, ${COLUMN_TO_COLOR[stageValue]}66 0%, #00000000 100%)`,
            }}
          >
            <StyledRightCornerDots height="62px" />

            <StageTitle>
              {stageValue}{" "}
              {stageRecords?.length ? `(${stageRecords?.length})` : null}
            </StageTitle>

            {dealNames?.map(dealName => {
              const dealRecords = recordsByName?.[dealName];

              return (
                <DealRecordCard
                  key={dealRecords?.[0]?._id}
                  schema={schema}
                  records={dealRecords}
                  onClickSaveRecord={onClickSaveRecord}
                />
              );
            })}
          </StageColumn>
        );
      })}
    </Columns>
  );
};

export default RecordsKanban;
