import styled from "styled-components";
import { getDisplayValue } from "utils/common";

const Card = styled.div`
  box-shadow: ${props => props.theme.shadow};
  padding: 10px;
  background-color: ${props => props.theme.color.closer0};
`;

const Value = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  /* color: ${props => props.theme.color.primary}; */
`;

const Label = styled.div`
  text-align: center;
  padding-bottom: 8px;
  font-weight: bold;
  color: ${props => props.theme.color.primary};
  font-size: 24px;
`;

const RecordsSingleNumber = ({ style = {}, records }) => {
  const columnName = Object.keys(records?.[0] || {})?.find(
    colName => colName !== ""
  );

  return (
    <Card style={style}>
      <Label>{columnName}</Label>
      <Value>{getDisplayValue({ record: records?.[0], columnName })}</Value>
    </Card>
  );
};

export default RecordsSingleNumber;
