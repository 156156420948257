import { useState, useEffect } from "react";

const resolveConfig = (config, topLevelConfig) => {
  const resolvedConfig = {};
  Object.entries(config).forEach(([key, value]) => {
    if (typeof value === "function") {
      resolvedConfig[key] = value(topLevelConfig);
      return;
    }

    resolvedConfig[key] = value;
  });
};

export const useConfig = INITIAL_CONFIG => {
  const [config, setConfig] = useState(INITIAL_CONFIG);

  useEffect(() => {
    const newConfig = resolveConfig(config, config);
    setConfig(newConfig);
  }, JSON.stringify(config));

  return [config, setConfig];
};
