import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import PageComponentOnRoute from "routes";
import CssReset from "global-styles/css-reset";
import Fonts from "global-styles/fonts";

export const theme = {
  name: "light",
  shadow: "1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12)",
  color: {
    furthest: "#24292F",
    closer0: "#24252A",
    closer1: "#4c4c4c",
    closer1_5: "#cacaca",
    closer2: "#879BA6",
    closest: "#DFE5EB",

    in_progress: "#d4a600",
    status_done: "#00C853",

    primary: "#0ECBB4",
    error: "#d9534f",
    success: "#28a745",

    feature: "#9650ff",
    data: "#009933",
  },
};

const App = () => {
  return (
    <BrowserRouter>
      <CssReset bgColor={theme.color.furthest} />
      <Fonts textColor={theme.color.closest} />
      <ThemeProvider theme={theme}>
        <PageComponentOnRoute />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
