import { cloneDeep, set } from "lodash";
import { useState } from "react";
import styled from "styled-components";

const IndentedDiv = styled.div`
  margin-left: 20px;
  border-left: 1px solid #eaeaea;
  line-height: 1.2;
`;

const GreySpan = styled.span`
  color: #828282;
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
`;

const Container = styled.div`
  height: ${props => (props.isCollapsed ? "0px" : "auto")};
  overflow: hidden;
`;

const CollapsibleKeyAndConfig = ({ keyName, config, setVars }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <GreySpan onClick={() => setIsCollapsed(!isCollapsed)}>
        {keyName}:
      </GreySpan>{" "}
      <ConfigViewer
        config={config[keyName]}
        setVars={setVars}
        topLevelConfig={config}
        isCollapsed={isCollapsed}
      />
    </>
  );
};

const ConfigViewer = ({
  config = {},
  setVars = () => {},
  topLevelConfig = {},
  isCollapsed = false,
  configKey = "",
}) => {
  if (typeof config === "string" && config?.includes("/")) {
    return (
      <a href={config}>
        {config?.length > 200 ? `${config?.slice(0, 20)}...` : config}
      </a>
    );
  }

  if (typeof config === "string" || config === null) {
    if (configKey?.endsWith("id")) {
      return config;
    }

    return (
      <input
        style={{ height: "20px", width: "200px" }}
        type="text"
        value={config || ""}
        onKeyDown={e => e.stopPropagation()}
        onChange={e => {
          const value = e.target.value;
          const newConfig = cloneDeep(topLevelConfig);
          set(newConfig, configKey.slice(1), value);
          setVars(newConfig);
        }}
      />
    );
  }
  if (typeof config === "number") {
    return (
      <input
        type="number"
        value={config || 0}
        onKeyDown={e => e.stopPropagation()}
        onChange={e => {
          const value = parseFloat(e.target.value);
          const newConfig = cloneDeep(topLevelConfig);
          set(newConfig, configKey.slice(1), value);
          setVars(newConfig);
        }}
      />
    );
  }
  if (typeof config === "boolean") {
    return `${config}`;
  }

  if (Array.isArray(config)) {
    return (
      <>
        [{isCollapsed && "..."}
        <Container isCollapsed={isCollapsed}>
          {config?.map((item, i) => (
            <IndentedDiv key={i}>
              <ConfigViewer
                config={item}
                setVars={setVars}
                configKey={`${configKey}.[${i}]`}
                topLevelConfig={topLevelConfig}
              />
            </IndentedDiv>
          ))}
          <div>]</div>
        </Container>
      </>
    );
  }

  const keys = Object.keys(config || {});

  if (keys?.includes("value") && keys?.includes("onChange")) {
    return (
      <input
        value={config?.value || ""}
        onKeyDown={e => e.stopPropagation()}
        onChange={e => config?.onChange(e.target.value, setVars)}
        type={config?.type || "text"}
      />
    );
  }

  if (keys?.includes("text") && keys?.includes("onClick")) {
    return (
      <button onClick={e => config?.onClick(topLevelConfig, setVars)}>
        {config?.text}
      </button>
    );
  }

  return (
    <>
      &#123;{isCollapsed && "..."}
      <Container isCollapsed={isCollapsed}>
        {keys?.map((key, i) => (
          <IndentedDiv key={i}>
            <GreySpan>{key}:</GreySpan>{" "}
            <ConfigViewer
              config={config[key]}
              setVars={setVars}
              topLevelConfig={topLevelConfig}
              configKey={`${configKey}.${key}`}
            />
            {/* <CollapsibleKeyAndConfig
              keyName={key}
              config={config}
              setVars={setVars}
            /> */}
          </IndentedDiv>
        ))}
        <div>&#125;</div>
      </Container>
    </>
  );
};

export default ConfigViewer;
