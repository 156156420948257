import { apiDelete, apiGet, apiPatch, apiPost } from "api/api-http-methods";

export const getDeal = async (id = "", params = {}) => {
  const { data, error } = await apiGet(
    `/tpicap-deal/api/v1/deals/${id}`,
    params
  );
  return { data, error };
};

export const getDeals = async (params = {}) => {
  const { data, error } = await apiGet("/tpicap-deal/api/v1/deals", params);
  return { data, error };
};

export const getEmail = async (id, params = {}) => {
  const { data, error } = await apiGet(
    `/tpicap-deal/api/v1/emails/${id}`,
    params
  );
  return { data, error };
};

export const getEmails = async (params = {}) => {
  const { data, error } = await apiGet("/tpicap-deal/api/v1/emails", params);
  return { data, error };
};

export const patchDeal = async (id, params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    `/tpicap-deal/api/v1/deals/${id}`,
    {},
    body
  );
  return { data, error };
};

export const patchEmail = async (id, params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    `/tpicap-deal/api/v1/emails/${id}`,
    params,
    body
  );
  return { data, error };
};

export const getExtractionSchema = async (params = {}) => {
  const { data, error } = await apiGet(
    "/tpicap-deal/api/v1/extraction-schema",
    params
  );
  return { data, error };
};

export const postAdhocIngestions = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/tpicap-deal/api/v1/adhoc-ingestions",
    params,
    body
  );
  return { data, error };
};

export const getParsedResults = async (params = {}) => {
  const { data, error } = await apiGet(
    "/tpicap-deal/api/v1/parsed-results",
    params
  );
  return { data, error };
};

export const getParsedResult = async (id, params = {}) => {
  const { data, error } = await apiGet(
    `/tpicap-deal/api/v1/parsed-results/${id}`,
    params
  );
  return { data, error };
};

export const patchParsedResult = async (id, params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    `/tpicap-deal/api/v1/parsed-results/${id}`,
    params,
    body
  );
  return { data, error };
};

export const getSummaryStats = async (params = {}) => {
  const { data, error } = await apiGet(
    "/tpicap-deal/api/v1/summary-stats",
    params
  );
  return { data, error };
};

export const postClear = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/tpicap-deal/api/v1/clear",
    params,
    body
  );
  return { data, error };
};

export const postFormatValue = async (params = {}, body = {}) => {
  const { data, error } = await apiPost(
    "/tpicap-deal/api/v1/format-value",
    params,
    body
  );
  return { data, error };
};

export const postParsedResultsCreateSibling = async (
  parsedResultId = "",
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/tpicap-deal/api/v1/parsed-results/${parsedResultId}/create-sibling`,
    params,
    body
  );
  return { data, error };
};

export const postEmailsMarkNoDeals = async (
  emailId,
  params = {},
  body = {}
) => {
  const { data, error } = await apiPost(
    `/tpicap-deal/api/v1/emails/${emailId}/mark-no-deals`,
    params,
    body
  );
  return { data, error };
};

export const deleteParsedResults = async (id, params = {}) => {
  const { data, error } = await apiDelete(
    `/tpicap-deal/api/v1/parsed-results/${id}`,
    params
  );
  return { data, error };
};

export const getDealsSearch = async (params = {}) => {
  const { data, error } = await apiGet("/tpicap-deal/api/v1/search", params);
  return { data, error };
};

export const postExecuteNlc = async (
  params = {},
  body = {},
  abortController = {}
) => {
  const { data, error } = await apiPost(
    "/tpicap-deal/api/v1/execute-nlc",
    params,
    body,
    abortController
  );
  return { data, error };
};

export const getRecommendedNlc = async (params = {}, abortController = {}) => {
  const { data, error } = await apiGet(
    "/tpicap-deal/api/v1/recommended-nlc",
    params,
    abortController
  );
  return { data, error };
};

export const getConfig = async (params = {}) => {
  const { data, error } = await apiGet("/tpicap-deal/api/v1/config", params);
  return { data, error };
};

export const patchConfig = async (params = {}, body = {}) => {
  const { data, error } = await apiPatch(
    "/tpicap-deal/api/v1/config",
    params,
    body
  );
  return { data, error };
};
