import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  getEmails,
  getExtractionSchema,
  postEmailsMarkNoDeals,
} from "api/services/dealService";
import EmailModal from "components/EmailModal";
import { getDeals } from "api/backend/dealEndpoints";
import TopNav from "components/TopNav";
import { safeFormat } from "utils/common";
import SideNav from "components/SideNav";
import { PencilIcon } from "components/ui/Icons";
import { Link } from "react-router-dom";
import { Style } from "@material-ui/icons";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: start;
  align-items: start;
`;

const Th = styled.th`
  padding: 8px;
  text-align: left;
  font-weight: 600;
  background-color: ${props => props.theme.color.closer0};
  border: 1px solid ${props => props.theme.color.closer1};
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const StyledButton = styled.button`
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 2px 12px;
  border-radius: 4px;
  font-weight: 600;
  color: ${props => props.theme.color.closer2};
  background-color: transparent;
  cursor: pointer;
  font-size: 11px;
  ${props =>
    props.isActive &&
    `
    background-color: ${props.theme.color.closer2};
    color: ${props.theme.color.furthest};
  `}

  :hover {
    background-color: ${props => props.theme.color.closer2};
    color: ${props => props.theme.color.furthest};
  }
`;

const getDisplayStatus = status => {
  const statusMap = {
    DONE: "Done",
    DONE_UNRESOLVED_DEAL: "Unresolved deal",
    FAILED: "Failed",
    DONE_NO_DEALS: "Ancillary",
    QUEUED: "Queued",
  };

  return statusMap[status] || status;
};

const StatusCard = styled.div`
  width: max-content;
  padding: 2px;

  background-color: ${props => props.theme.color.in_progress}22;
  color: ${props => props.theme.color.in_progress};

  ${props =>
    props.status === "DONE" &&
    `
    background-color: ${props.theme.color.success}22;
    color: ${props.theme.color.success};
  `}

  ${props =>
    props.status === "FAILED" &&
    `
    background-color: ${props.theme.color.error}22;
    color: ${props.theme.color.error};
  `}
`;

const MissingPage = () => {
  const [schema, setSchema] = useState({});
  const [emailRecords, setEmailrecords] = useState([]);
  const [deals, setDeals] = useState([]);
  const [statusFilter, setStatusFilter] = useState("ALL"); // DONE, DONE_NO_DEALS

  useEffect(() => {
    doPopulateEmails();
    doPopulateDeals();

    const intervalId = setInterval(() => {
      doPopulateDeals();
      doPopulateEmails();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    doPopulateSchema();
  }, []);

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  const doPopulateEmails = async () => {
    const { data } = await getEmails();
    setEmailrecords(data);
  };

  const doPopulateDeals = async () => {
    const { data } = await getDeals();
    setDeals(data);
  };

  const doMarkNoDeal = async id => {
    await postEmailsMarkNoDeals(id);
    doPopulateEmails();
  };

  const sortedEmailRecords = emailRecords
    ?.sort((a, b) => {
      const aDate = new Date(a?.email?.date);
      const bDate = new Date(b?.email?.date);
      return bDate - aDate;
    })
    ?.filter(record => {
      if (statusFilter === "ALL") {
        return (
          record?.parsingStatus !== "DONE" &&
          record?.parsingStatus !== "DONE_NO_DEALS"
        );
      }
      return record?.parsingStatus === statusFilter;
    });

  // ?.filter(record => {
  //   if (isShowingNoDealEmails) {
  //     return record?.parsingStatus === "DONE_NO_DEALS";
  //   }
  //   if (isShowingDoneEmails) {
  //     return record?.parsingStatus === "DONE";
  //   }
  //   return record?.parsingStatus !== "DONE_NO_DEALS";
  // });

  return (
    <Container>
      <div style={{ width: "60px" }} />
      <SideNav />
      <div style={{ width: "100%" }}>
        <div
          style={{
            padding: "8px",
            display: "flex",
            gap: "8px",
          }}
        >
          <StyledButton
            isActive={statusFilter === "ALL"}
            onClick={() => setStatusFilter("ALL")}
          >
            All
          </StyledButton>
          <StyledButton
            isActive={statusFilter === "DONE_NO_DEALS"}
            onClick={() => setStatusFilter("DONE_NO_DEALS")}
          >
            Ancillary
          </StyledButton>
          <StyledButton
            isActive={statusFilter === "DONE"}
            onClick={() => setStatusFilter("DONE")}
          >
            Done
          </StyledButton>
        </div>

        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <Th>Email Subject</Th>
              <Th>Received time</Th>
              <Th>Status</Th>
            </tr>
          </thead>
          <tbody>
            {sortedEmailRecords?.map(record => {
              const { subject, date } = record?.email || {};
              const status = record?.parsingStatus;

              return (
                <tr key={record?._id}>
                  <Td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Link to={`/emails/${record?._id}`}>
                        <PencilIcon style={{ height: "10px", fill: "white" }} />
                      </Link>
                      {/* <EmailModal
                      style={{
                        opacity: status === "FAILED" ? 0.2 : 1,
                        pointerEvents: status === "FAILED" ? "none" : "auto",
                      }}
                      emailRecord={record}
                      schema={schema}
                      deals={deals}
                      onPressSaveParsedResult={() => doPopulateEmails()}
                    /> */}
                      {subject}
                    </div>
                  </Td>
                  <Td>{safeFormat(new Date(date), "d MMM yyyy, HH:mm")}</Td>
                  <Td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "4px",
                      }}
                    >
                      <StatusCard status={status}>
                        {getDisplayStatus(status)}
                      </StatusCard>
                      {record?.parsingStatus !== "DONE_NO_DEALS" &&
                        record?.parsingStatus !== "DONE" && (
                          <StyledButton
                            onClick={() => doMarkNoDeal(record?._id)}
                          >
                            Mark ancillary
                          </StyledButton>
                        )}
                    </div>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
        }}
      >
        {emailRecords?.map(emailRecord => (
          <Card key={emailRecord?._id}>
            <div
              style={{
                height: "100px",
              }}
            >
              {emailRecord?.email?.subject}
            </div>
            {emailRecord?.parsingStatus}
            <EmailModal
              style={{ position: "absolute", top: "12px", right: "4px" }}
              emailRecord={emailRecord}
              schema={schema}
              deals={deals}
              onPressSaveParsedResult={() => doPopulateEmails()}
            />
            <div
              style={{
                marginTop: "8px",
                fontSize: "10px",
                color: "#888",
              }}
            >
              {safeFormat(
                new Date(emailRecord?.email?.date),
                "dd/MM/yyyy HH:mm:ss"
              )}
            </div>
          </Card>
        ))}
      </div> */}
      </div>
    </Container>
  );
};

export default MissingPage;
