import { useState, useEffect } from "react";
import styled from "styled-components";

import { CrossIcon, PencilIcon } from "components/ui/Icons";
import Modal from "components/ui/Modal";
import { getParsedResult, patchParsedResult } from "api/services/dealService";
import DealFieldsEditor from "components/DealFieldsEditor";
import SpanWithHoverTooltip from "components/SpanWithHoverTooltip";
import { clone, cloneDeep, isNil, set } from "lodash";
import { strToColor } from "utils/common";
import { getTextSegments } from "components/DealDetailsModalTrigger";
import AnnotatedText from "components/AnnotatedText";

const ModalContent = styled.div`
  position: relative;
  padding: 10px;
  background-color: ${props => props.theme.color.furthest};
  width: 95vw;
  height: 95vh;
  outline: none;
  overflow: hidden;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Tab = styled.div`
  padding: 8px 12px;
  font-weight: 500;
  color: ${props =>
    props.isSelected ? props.theme.color.primary : props.theme.color.closer1_5};
  border-bottom: 2px solid
    ${props =>
      props.isSelected
        ? props.theme.color.primary
        : props.theme.color.closer1_5};
  cursor: pointer;
`;

const StyledPencilIcon = styled(PencilIcon)`
  height: 16px;
  fill: white;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const EmailModal = ({
  style = {},
  emailRecord = {},
  schema = {},
  deals = {},
  onPressSaveParsedResult = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [parsedResults, setParsedResults] = useState([]);
  const [selectedParsedResultId, setSelectedParsedResultId] = useState(null);
  const [selectedDealId, setSelectedDealId] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (!emailRecord?.parsedResultIds?.length) {
      return;
    }

    setParsedResults([]);
    doPopulateParsedResults(emailRecord?.parsedResultIds);
    setSelectedParsedResultId(emailRecord?.parsedResultIds?.[0]);
  }, [emailRecord?.parsedResultIds?.length]);

  const doPopulateParsedResults = async parsedResultIds => {
    parsedResultIds.forEach(async parsedResultId => {
      const { data } = await getParsedResult(parsedResultId);
      setParsedResults(prev => [...prev, data]);
    });
  };

  const updateParsedResult = (id, newParsedResult) => {
    if (!parsedResults?.length) {
      setParsedResults([{ _id: id, ...newParsedResult }]);
      return;
    }

    const newParsedResults = parsedResults.map(parsedResult =>
      parsedResult?._id === id
        ? { ...parsedResult, ...newParsedResult }
        : parsedResult
    );
    setParsedResults(newParsedResults);
  };

  const updateSelectedResultName = newName => {
    const extractedFields = cloneDeep(selectedParsedResult?.extractedFields);
    set(extractedFields, "name.value", newName);
    set(extractedFields, "name.meta.manuallyEntered", true);
    updateParsedResult(selectedParsedResultId, {
      extractedFields,
    });
  };

  const doPatchSelectedParsedResult = async () => {
    setIsDisabled(true);
    const { data } = await patchParsedResult(
      selectedParsedResultId,
      {},
      {
        extractedFields: selectedParsedResult?.extractedFields,
      }
    );
    updateParsedResult(selectedParsedResultId, data);
    setIsDisabled(false);
    setIsOpen(false);
    onPressSaveParsedResult();
  };

  const selectedParsedResult = parsedResults.find(
    parsedResult => parsedResult?._id === selectedParsedResultId
  );

  return (
    <>
      <StyledPencilIcon style={style} onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isDisabled}>
          <Tabs>
            {!parsedResults?.length && <Tab isSelected>Tranche 1</Tab>}
            {parsedResults.map((parsedResult, i) => (
              <Tab
                key={parsedResult?._id}
                isSelected={parsedResult?._id === selectedParsedResultId}
                onClick={() => setSelectedParsedResultId(parsedResult?._id)}
              >
                Tranche {i + 1}
              </Tab>
            ))}
          </Tabs>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 500px",
            }}
          >
            <div style={{ overflow: "auto", height: "800px" }}>
              <div
                style={{
                  gap: "8px",
                  display: "flex",
                }}
              >
                <select
                  value={selectedDealId}
                  onChange={e => {
                    setSelectedDealId(e.target.value);
                    const selectedDeal = deals?.find(
                      deal => deal?._id === e.target.value
                    );

                    const ticker = selectedDeal?.extractedFields?.Ticker?.value;
                    const currency =
                      selectedDeal?.extractedFields?.Currency?.value;
                    const maturity =
                      selectedDeal?.extractedFields?.Maturity?.value;
                    const series = selectedDeal?.extractedFields?.Series?.value;

                    const newExtractedFields = cloneDeep(
                      selectedParsedResult?.extractedFields
                    );
                    set(newExtractedFields, "Ticker.value", ticker);
                    set(newExtractedFields, "Currency.value", currency);
                    set(newExtractedFields, "Maturity.value", maturity);
                    set(newExtractedFields, "Series.value", series);

                    updateParsedResult(selectedParsedResultId, {
                      extractedFields: newExtractedFields,
                    });
                  }}
                >
                  <option value="">-- Choose existing deal --</option>
                  {deals?.map(deal => (
                    <option key={deal?._id} value={deal?._id}>
                      {deal?.extractedFields?.name?.value}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  paddingRight: "10px",
                  overflow: "auto",
                  borderBottom: "1px solid #ccc",
                  height: "620px",

                  display: "grid",
                  gridTemplateRows: "auto auto",
                }}
              >
                <DealFieldsEditor
                  fields={selectedParsedResult?.extractedFields}
                  schema={schema}
                  onChange={extractedFields =>
                    updateParsedResult(selectedParsedResultId, {
                      extractedFields,
                    })
                  }
                  isJsonBtnVisible
                />
              </div>
            </div>
            <div>
              <AnnotatedText
                style={{
                  height: "550px",
                  // overflow: "auto",
                }}
                parsedResult={selectedParsedResult?.extractedFields}
                schema={schema}
                text={selectedParsedResult?.text}
                subject={selectedParsedResult?.subject}
                onChangeParsedResult={extractedFields => {
                  updateParsedResult(selectedParsedResultId, {
                    extractedFields,
                  });
                }}
              />
            </div>
          </div>

          <button
            style={{
              position: "absolute",
              bottom: "12px",
            }}
            onClick={() => doPatchSelectedParsedResult()}
          >
            Save
          </button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailModal;
