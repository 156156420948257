import { Fade, Backdrop, Modal as MuiModal } from "@material-ui/core";
import styled from "styled-components";

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
`;

const ModalTitle = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 22px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const Modal = ({ handleClose, open, children, title }) => (
  <MuiModal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
  >
    <Fade in={open}>
      <ModalContent>
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
      </ModalContent>
    </Fade>
  </MuiModal>
);

export default Modal;
