import { useState, useEffect } from "react";
import styled from "styled-components";
import { round } from "lodash";

import {
  getDealsSearch,
  getExtractionSchema,
  getSummaryStats,
  patchDeal,
} from "api/services/dealService";
import RecordsKanban from "components/RecordsKanban";
import { isArchivedDeal } from "utils/common";
import SideNav from "components/SideNav";

const DASHBOARD = {
  layout: ["source_records", "records_battery"],
  components: [
    {
      id: "source_records",
      name: "All deals",
      records: [],
    },
  ],
};

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 10px;
`;

const StatsContainer = styled.div`
  height: max-content;

  display: grid;
  grid-auto-flow: column;
  padding-top: 10px;
`;

const StatsAndSearch = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  padding-right: 8px;
`;

const StyledInput = styled.input`
  color-scheme: dark;
  border: 1px solid transparent;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
  outline: none;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #181a1f;
  font-family: "Inter";
`;

const Stat = styled.div`
  display: grid;
  gap: 4px;
`;

const StatTitle = styled.div`
  font-size: 11px;
  color: ${props => props.theme.color.closer2};
`;

const StatValue = styled.div`
  font-size: 17px;
  color: ${props => props.theme.color.closest};
`;

const StyledSelect = styled.select`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledButton = styled.button`
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
  color: ${props => props.theme.color.closer2};
  background-color: transparent;
  cursor: pointer;
  font-size: 11px;
  ${props => props.disabled && "opacity: 0.5; pointer-events: none;"}

  :hover {
    background-color: ${props => props.theme.color.closer2};
    color: ${props => props.theme.color.furthest};
  }
`;

export const STATS = [
  "Total Deals",
  "PB Deals",
  "Not PB Deals",
  "Coverage",
  "Tranches",
  "Total Issue Size",
  "Total Book Size",
  "Subscription Ratio",
];

export const formatStatValue = (label, value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  if (typeof value !== "number") {
    return value;
  }

  if (label === "Coverage") {
    return `${round(value * 100, 2)}%`;
  }

  if (
    ["Total Issue Size", "Total Book Size"].includes(label) ||
    value > 10 ** 6
  ) {
    return `${formatter.format(value * 10 ** -6)} MM`;
  }

  return round(value, 2);
};

const today = new Date().toISOString()?.split("T")?.[0];

const DashboardPage = () => {
  const [dashboard, setDashboard] = useState(DASHBOARD);
  const [selectedField, setSelectedField] = useState("Tranche Status");
  const [allRecords, setAllRecords] = useState([]);
  const [schema, setSchema] = useState({});
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchApiFilter, setSearchApiFilter] = useState("");
  const [stats, setStats] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    doPopulateSchema();
  }, []);

  useEffect(() => {
    doPopulateDeals();

    const intervalId = setInterval(() => {
      doPopulateDeals();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [searchApiFilter]);

  useEffect(() => {
    doPopulateStats();

    const intervalId = setInterval(() => {
      doPopulateStats();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [selectedField, searchApiFilter]);

  useEffect(() => {
    if (searchInputValue === "") {
      setSearchApiFilter("");
      setIsSearching(true);
    }
  }, [searchInputValue]);

  const doPopulateStats = async () => {
    const { data } = await getSummaryStats({
      search: searchApiFilter,
      orStatuses: ["Books Open", "Books Subject", "Books Closed", "Allocated"],
      startTime: today,
    });

    const newStats = [];
    STATS.forEach(statName => {
      newStats.push({
        label: statName,
        value: data?.[statName],
      });
    });

    setStats(newStats);
  };

  const doPopulateSchema = async () => {
    const { data } = await getExtractionSchema();
    setSchema(data);
  };

  const doPopulateDeals = async () => {
    const { data, error } = await getDealsSearch({
      search: searchApiFilter,
      orStatuses: ["Books Open", "Books Subject", "Books Closed", "Allocated"],
      startTime: today,
    });
    if (error?.type === "Unauthorised") {
      window.location = "/login";
      return;
    }

    setAllRecords(data);
    setIsSearching(false);
  };

  const doPatchRecord = async (id, body) => {
    const { data: updatedRecord } = await patchDeal(id, {}, body);
    const newRecords = allRecords.map(record =>
      record._id === id ? updatedRecord : record
    );
    setAllRecords(newRecords);
  };

  const records = allRecords;

  return (
    <Container>
      <div style={{ width: "60px" }} />
      <SideNav style={{ gridRow: "span 2" }} />
      <StatsAndSearch>
        <StatsContainer>
          {stats.map(stat => (
            <Stat>
              <StatTitle>{stat.label}</StatTitle>
              <StatValue>{formatStatValue(stat?.label, stat.value)}</StatValue>
            </Stat>
          ))}
        </StatsContainer>
        <StyledInput
          placeholder="Search"
          value={searchInputValue}
          onChange={e => setSearchInputValue(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              setSearchApiFilter(searchInputValue);
            }
          }}
        />
        <StyledButton
          disabled={isSearching}
          onClick={() => setSearchApiFilter(searchInputValue)}
        >
          Search
        </StyledButton>
      </StatsAndSearch>
      <div style={{ paddingTop: "0px", overflow: "auto", gridColumn: 2 }}>
        {dashboard?.layout?.map(componentId => {
          const component = DASHBOARD?.components?.find(
            cmp => cmp.id === componentId
          );

          if (componentId === "source_records") {
            return (
              <RecordsKanban
                key={componentId}
                selectedField={selectedField}
                records={records}
                onClickSaveRecord={async (id, body) => {
                  setIsSearching(true);
                  await doPatchRecord(id, body);
                  await doPopulateDeals();
                }}
                schema={schema}
              />
            );
          }

          // if (componentId === "records_battery") {
          //   return (
          //     <div key={componentId} style={{ height: "100px", width: "100%" }}>
          //       <RecordsSummaryChart
          //         type={component?.type}
          //         key={componentId}
          //         name={selectedField}
          //         columns={statsColumns}
          //         records={[stats]}
          //       />
          //     </div>
          //   );
          // }

          // return <div key={componentId}>{JSON.stringify(component)}</div>;
        })}
      </div>
    </Container>
  );
};

export default DashboardPage;
