import styled from "styled-components";
import { cloneDeep, set } from "lodash";

import MultilevelSelect from "components/MultilevelSelect";
import { REQUIRED_FIELDS, getDeals } from "api/services/dealService";
import { useState } from "react";
import { ChevronDown, FilesIcon, PinIcon } from "components/ui/Icons";
import { useEffect } from "react";
import SearchableSelect from "components/SearchableSelect";
import ArrayInput from "components/ArrayInput";
import { Group } from "@material-ui/icons";
import { COLUMN_TO_COLOR } from "components/RecordsKanban";

export const LEFT_COLUMN_GROUPS = [
  "Tranche Details",
  "TAP",
  "Dates",
  "Issuer",
  "Syndicates",
  "Timings",
];

export const RIGHT_COLUMN_GROUPS = [
  "Coupon",
  "Guarantor",
  "Identifiers",
  "Geography",
  "Pricing Details",
  "Benchmark",
];

export const BOTTOM_GROUPS = ["Regulations", "Notes"];

export const ALL_GROUPS = [
  ...LEFT_COLUMN_GROUPS,
  ...RIGHT_COLUMN_GROUPS,
  ...BOTTOM_GROUPS,
];

const LONG_FIELDS = [];

const GROUP_NAME_TO_LAYOUT = {};
const OPEN_GROUPS = ["Tranche Details", "Pricing Details"];

ALL_GROUPS.filter(name => !OPEN_GROUPS?.includes(name)).forEach(groupName => {
  GROUP_NAME_TO_LAYOUT[groupName] = {
    isClosed: true,
  };
});

const GROUPS_1 = ["Tranche Details", "TAP", "Dates"];

const GROUPS_2 = ["Issuer", "Syndicates", "Timings", "Coupon"];

const GROUPS_3 = ["Guarantor", "Identifiers", "Geography", "Pricing Details"];

const GROUPS_4 = ["Benchmark", "Regulations", "Notes"];

const FINAL_STATUS = "Deal Priced";

const Input = styled.input`
  background-color: transparent;
  color: white;
  color-scheme: dark;
  padding: 2px 0;

  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  font-size: 11px;
  color-scheme: dark;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const TextArea = styled.textarea`
  background-color: transparent;
  color: white;

  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  resize: none;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Select = styled.select`
  background-color: transparent;
  color: white;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";
  resize: none;
  max-width: 130px;
  font-size: 11px;
  padding: 2px 0;
  color-scheme: dark;
  background-color: #181a1f;

  :focus {
    border-bottom: 1px solid ${props => props.theme.color.primary};
  }
`;

const StatusSelect = styled.select`
  background-color: ${props => props.theme.color.closer0};
  color: ${props =>
    COLUMN_TO_COLOR?.[props.statusValue] || props.theme.color.closest};
  outline: none;
  border-radius: 0;
  border: none;
  font-family: "Inter";
  resize: none;
  max-width: 130px;
  font-size: 11px;
  padding: 4px 16px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
`;

const FieldName = styled.div`
  color: ${props =>
    props.isParsingActive
      ? props.theme.color.primary
      : props.theme.color.closer2};
  font-weight: 600;
  padding-bottom: 4px;
  ${props =>
    props.isHighlighted && `outline: 1px solid ${props.theme.color.primary};`}
`;

const AVRO_TO_INPUT_TYPE = {
  string: "text",
  int: "number",
  double: "number",
  enum: "select",
  record: "multilevel",
  boolean: "checkbox",
  array: "array",
};

const getInputType = field => {
  if (field?._meta?.format === "timestamp") {
    return "date";
  }

  if (field?._meta?.format === "textarea") {
    return "textarea";
  }

  return AVRO_TO_INPUT_TYPE[field?.type] || "text";
};

const Container = styled.div`
  display: grid;
`;

const JsonViewer = styled.div`
  line-height: 1.2;
  font-family: monospace;
  font-size: 12px;
  position: relative;
  padding-bottom: 40px;
  color: #00ff41;
`;

const IconContainer = styled.div`
  svg {
    height: 16px;
    width: 16px;
    fill: ${props => props.theme.color.closer2};
  }
  padding: 4px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const GroupName = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  ${props =>
    props.isHighlighted && `outline: 1px solid ${props.theme.color.primary};`};

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const StyledPinIcon = styled(PinIcon)`
  height: 12px;
  fill: ${props => props.theme.color.closer2};
  ${props => (props.isPinned ? "transform: rotate(-45deg);" : "")}
  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const GroupFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  column-gap: 8px;
`;

const GroupContainer = styled.div`
  display: grid;
  align-items: center;
  border-radius: 2px;
  background-color: #181a1f;
  color: ${props => props.theme.color.closest};
  padding: 10px;
  box-shadow: ${props => props.theme.shadow};
  font-size: 11px;
  align-content: start;
  height: ${props => (props.isClosed ? "36px" : "max-content")};
  width: 100%;

  justify-self: start;
  padding-bottom: 12px;
  margin-bottom: 10px;
  break-inside: avoid;
  /* width: min-content; */

  overflow: hidden;
`;

const FieldLabelContainer = styled.label`
  display: grid;
  align-content: start;
  gap: 4px;
`;

const FieldDivContainer = styled.div`
  display: grid;
  align-content: start;
  gap: 4px;
`;

const GroupsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: start;
  gap: 8px;
`;

const GroupsColumn = styled.div``;

const StyledButton = styled.button`
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 2px 12px;
  border-radius: 4px;
  font-weight: 600;
  color: ${props => props.theme.color.closer2};
  background-color: transparent;
  cursor: pointer;
  font-size: 11px;

  :hover {
    background-color: ${props => props.theme.color.closer2};
    color: ${props => props.theme.color.furthest};
  }
`;

const StyledChevronDown = styled(ChevronDown)`
  height: 16px;
  width: 12px;
  fill: ${props => props.theme.color.closest};
`;

const StyledChevronRight = styled(ChevronDown)`
  height: 16px;
  width: 12px;
  fill: ${props => props.theme.color.closest};
  transform: rotate(-90deg);
`;

const TopBar = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: grid;
  gap: 8px;
`;

const TopBarRow = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto auto auto auto;
  gap: 12px;
  align-items: center;
`;

const ScrollableContent = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledInputSearch = styled.input`
  color-scheme: dark;
  border: 1px solid transparent;
  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
  outline: none;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #181a1f;
  font-family: "Inter";
`;

const DealName = styled.div`
  font-size: 21px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;

const DealFieldsEditor = ({
  dealId = "",
  layout = GROUP_NAME_TO_LAYOUT,
  onNewLayout = newLayout => {},
  fields = {},
  onChange,
  schema = {},
  isJsonBtnVisible = false,
  onClickSave = () => {},
}) => {
  const [isViewingJson, setIsViewingJson] = useState(false);

  const [selectedDealId, setSelectedDealId] = useState("");
  const [deals, setDeals] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    doPopulateDeals();
  }, []);

  const allFields = schema?.fields?.map(field => field.name);

  useEffect(() => {
    const { Ticker, Currency, Maturity, Series } = fields || {};
    const couponTypeValue = fields?.["Coupon Type"]?.value;

    const newName = `${Ticker?.value || "[TICKER]"} ${
      Currency?.value || "[CURRENCY]"
    } ${Maturity?.value || "[MATURITY]"} ${couponTypeValue || "[COUPONTYPE]"} ${
      Series?.value || "[SERIES]"
    }`;

    const newFields = cloneDeep(fields);
    if (!newFields?.name) {
      newFields.name = {
        value: "",
        meta: {
          manuallyEntered: true,
        },
      };
    }
    newFields.name.value = newName;
    newFields.name.meta.manuallyEntered = true;

    onChange(newFields);
  }, [JSON.stringify(fields)]);

  const doPopulateDeals = async () => {
    const { data } = await getDeals();
    setDeals(data);
  };

  const renderGroup = (groupNames = []) => {
    return (
      <>
        {groupNames?.map(groupName => {
          const groupFields = allFields?.filter(
            fieldName =>
              schema?.fields?.find(field => field.name === fieldName)?._meta
                ?.groupName === groupName
          );

          const isClosed = layout?.[groupName]?.isClosed;
          const isPinned = layout?.[groupName]?.isPinned;
          const doesGroupIncludeSearchedField = groupFields?.some(
            fieldName =>
              searchValue &&
              fieldName?.toLowerCase()?.includes(searchValue?.toLowerCase())
          );

          return (
            <GroupContainer isClosed={isClosed} key={groupName}>
              <GroupName isHighlighted={doesGroupIncludeSearchedField}>
                <div
                  onClick={() => {
                    onNewLayout({
                      ...layout,
                      [groupName]: {
                        isClosed: !isClosed,
                      },
                    });
                  }}
                  style={{ display: "flex", gap: "4px" }}
                >
                  {isClosed ? <StyledChevronRight /> : <StyledChevronDown />}
                  {groupName}
                </div>
                <StyledPinIcon
                  isPinned={isPinned}
                  onClick={() => {
                    onNewLayout({
                      ...layout,
                      [groupName]: {
                        isClosed: false,
                        isPinned: !isPinned,
                      },
                    });
                  }}
                />
              </GroupName>
              <GroupFields>
                {groupFields?.map(fieldName => {
                  const field = schema?.fields?.find(
                    field => field.name === fieldName
                  );

                  const type = getInputType(field) || "text";
                  const symbols = field?.symbols || [];
                  const meta = field?._meta || {};
                  const defaultValue = field?.default || "";

                  const onChangeFieldValue = e => {
                    const newFields = cloneDeep(fields);
                    if (!newFields[fieldName]) {
                      newFields[fieldName] = {
                        value: "",
                        meta: {},
                      };
                    }
                    newFields[fieldName].value = e.target.value;
                    newFields[fieldName].meta.manuallyEntered = true;
                    newFields[fieldName].meta.defaultValue = false;

                    onChange(newFields);
                  };

                  const onChangeCheckboxFieldValue = e => {
                    const newFields = cloneDeep(fields);
                    if (!newFields[fieldName]) {
                      newFields[fieldName] = {
                        value: false,
                        meta: {},
                      };
                    }
                    newFields[fieldName].value = e.target.checked;
                    newFields[fieldName].meta.manuallyEntered = true;
                    newFields[fieldName].meta.defaultValue = false;

                    onChange(newFields);
                  };

                  // for custom inputs
                  const onChangeField = newFieldValue => {
                    const newFields = cloneDeep(fields);
                    newFields[fieldName] = newFieldValue;
                    onChange(newFields);
                  };

                  if (type === "array") {
                    return (
                      <FieldDivContainer
                        key={`${groupName}-${fieldName}`}
                        style={{
                          gridColumn: type === "multilevel" ? "span 2" : "",
                        }}
                      >
                        <FieldName
                          isParsingActive={field?.parsingActive}
                          isHighlighted={
                            searchValue &&
                            fieldName
                              ?.toLowerCase()
                              ?.includes(searchValue?.toLowerCase())
                          }
                        >
                          {fieldName}
                        </FieldName>
                        <ArrayInput
                          placeholder={fieldName}
                          value={fields?.[fieldName]?.value}
                          items={field?.items}
                          onChange={newArray =>
                            onChangeField({
                              meta: {
                                manuallyEntered: true,
                              },
                              value: newArray,
                            })
                          }
                        />
                      </FieldDivContainer>
                    );
                  }

                  return (
                    <FieldLabelContainer
                      key={`${groupName}-${fieldName}`}
                      style={{
                        gridColumn: type === "multilevel" ? "span 2" : "",
                      }}
                    >
                      <FieldName
                        isParsingActive={field?.parsingActive}
                        isHighlighted={
                          searchValue &&
                          fieldName
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase())
                        }
                      >
                        {fieldName}
                      </FieldName>

                      {["date", "text", "number"]?.includes(type) && (
                        <Input
                          type={type}
                          value={fields?.[fieldName]?.value || defaultValue}
                          onChange={onChangeFieldValue}
                        />
                      )}

                      {type === "checkbox" && (
                        <Input
                          style={{
                            width: "max-content",
                          }}
                          type="checkbox"
                          checked={fields?.[fieldName]?.value || defaultValue}
                          onChange={onChangeCheckboxFieldValue}
                        />
                      )}

                      {type === "textarea" && (
                        <TextArea
                          value={fields?.[fieldName]?.value || defaultValue}
                          onChange={onChangeFieldValue}
                        />
                      )}

                      {type === "select" && (
                        <Select
                          value={fields?.[fieldName]?.value || defaultValue}
                          onChange={onChangeFieldValue}
                          style={
                            LONG_FIELDS?.includes(fieldName)
                              ? { maxWidth: "165px" }
                              : {}
                          }
                        >
                          <option value=""></option>
                          {symbols?.map(option => (
                            <option key={option}>{option}</option>
                          ))}
                        </Select>
                      )}

                      {type === "multilevel" && (
                        <MultilevelSelect
                          style={{ gridColumn: "span 2" }}
                          fields={field?.fields}
                          value={fields?.[fieldName]}
                          onChange={onChangeField}
                        />
                      )}

                      {type === "array" && (
                        <ArrayInput
                          placeholder={fieldName}
                          value={fields?.[fieldName]?.value}
                          items={field?.items}
                          onChange={newArray =>
                            onChangeField({
                              meta: {
                                manuallyEntered: true,
                              },
                              value: newArray,
                            })
                          }
                        />
                      )}
                    </FieldLabelContainer>
                  );
                })}
              </GroupFields>
            </GroupContainer>
          );
        })}
      </>
    );
  };

  const statusOptions =
    schema?.fields?.find(field => field.name === "Tranche Status")?.symbols ||
    [];

  const areAllExpanded = Object.values(layout).every(
    layout => !layout?.isClosed
  );

  return (
    <Container>
      <TopBar>
        <TopBarRow style={{ gridTemplateColumns: "1fr auto auto" }}>
          <DealName>{fields?.name?.value || ""}</DealName>
          <StyledInputSearch
            placeholder="Search fields"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
          <SearchableSelect
            style={{
              display: dealId ? "block" : "none",
              justifySelf: "start",
              maxWidth: "none",
              width: "200px",
            }}
            value={selectedDealId}
            onChange={e => {
              setSelectedDealId(e.target.value);
              const selectedDeal = deals?.find(
                deal => deal?._id === e.target.value
              );
              const ticker = selectedDeal?.extractedFields?.Ticker?.value;
              const currency = selectedDeal?.extractedFields?.Currency?.value;
              const maturity = selectedDeal?.extractedFields?.Maturity?.value;
              const series = selectedDeal?.extractedFields?.Series?.value;
              const couponType =
                selectedDeal?.extractedFields?.["Coupon Type"]?.value;
              const newExtractedFields = cloneDeep(fields);
              set(newExtractedFields, "Ticker.value", ticker);
              set(newExtractedFields, "Currency.value", currency);
              set(newExtractedFields, "Maturity.value", maturity);
              set(newExtractedFields, "Series.value", series);
              set(newExtractedFields, "Coupon Type.value", couponType);

              onChange(newExtractedFields);
            }}
            placeholder="Merge into existing deal"
            options={deals?.map(deal => ({
              name: deal?.extractedFields?.name?.value,
              value: deal?._id,
            }))}
          />
          {/* <Select
            style={{
              display: dealId ? "block" : "none",
              justifySelf: "start",
              maxWidth: "none",
            }}
            value={selectedDealId}
            onChange={e => {
              setSelectedDealId(e.target.value);
              const selectedDeal = deals?.find(
                deal => deal?._id === e.target.value
              );
              const ticker = selectedDeal?.extractedFields?.Ticker?.value;
              const currency = selectedDeal?.extractedFields?.Currency?.value;
              const maturity = selectedDeal?.extractedFields?.Maturity?.value;
              const series = selectedDeal?.extractedFields?.Series?.value;
              const couponType =
                selectedDeal?.extractedFields?.["Coupon Type"]?.value;
              const newExtractedFields = cloneDeep(fields);
              set(newExtractedFields, "Ticker.value", ticker);
              set(newExtractedFields, "Currency.value", currency);
              set(newExtractedFields, "Maturity.value", maturity);
              set(newExtractedFields, "Series.value", series);
              set(newExtractedFields, "Coupon Type.value", couponType);

              onChange(newExtractedFields);
            }}
          >
            <option value="">-- Merge into existing deal --</option>
            {deals
              ?.filter(d => d?._id !== dealId)
              ?.map(deal => (
                <option key={deal?._id} value={deal?._id}>
                  {deal?.extractedFields?.name?.value}
                </option>
              ))}
          </Select> */}
        </TopBarRow>
        <TopBarRow>
          <div
            style={{
              gridColumn: "span 2",
              display: "flex",
              alignItems: "center",
              padding: "4px 0",
            }}
          >
            <ArrayInput
              value={fields?.["Partner bank"]?.value}
              onChange={newValue => {
                const newFields = cloneDeep(fields);
                set(newFields, "Partner bank.value", newValue);
                set(newFields, "Partner bank.meta.manuallyEntered", true);
                onChange(newFields);
              }}
              items={
                schema?.fields?.find(field => field?.name === "Partner bank")
                  ?.items
              }
            />
          </div>

          <IconContainer
            style={{
              opacity: isViewingJson ? 1 : 0,
              pointerEvents: isViewingJson ? "all" : "none",
            }}
            onClick={() =>
              navigator.clipboard.writeText(JSON.stringify(fields, null, 2))
            }
          >
            <FilesIcon />
          </IconContainer>

          <StyledButton
            onClick={() => {
              const newLayout = {};
              ALL_GROUPS.forEach(groupName => {
                if (layout[groupName]?.isPinned) {
                  newLayout[groupName] = {
                    isPinned: true,
                    isClosed: false,
                  };
                  return;
                }
                newLayout[groupName] = {
                  isClosed: areAllExpanded,
                };
              });
              onNewLayout(newLayout);
            }}
          >
            {areAllExpanded ? "Collapse all" : "Expand all"}
          </StyledButton>

          {(fields?.status?.value === FINAL_STATUS ||
            isJsonBtnVisible ||
            true) && (
            <StyledButton onClick={() => setIsViewingJson(!isViewingJson)}>
              {isViewingJson ? "Form" : "JSON"}
            </StyledButton>
          )}

          <StatusSelect
            statusValue={fields?.["Tranche Status"]?.value || ""}
            value={fields?.["Tranche Status"]?.value || ""}
            onChange={e => {
              const newFields = cloneDeep(fields);
              set(newFields, "Tranche Status.value", e.target.value);
              set(newFields, "Tranche Status.meta.manuallyEntered", true);
              onChange(newFields);
            }}
          >
            <option value="">-</option>
            {statusOptions?.map(status => (
              <option>{status}</option>
            ))}
          </StatusSelect>

          <StyledButton onClick={onClickSave}>Save</StyledButton>
        </TopBarRow>
      </TopBar>

      <ScrollableContent>
        {isViewingJson && (
          <JsonViewer
            style={{
              whiteSpace: "pre-wrap",
              gridColumn: "span 2",
            }}
          >
            {JSON.stringify(fields, null, 2)}
          </JsonViewer>
        )}

        {!isViewingJson && (
          <>
            <GroupsContainer>
              <GroupsColumn>{renderGroup(GROUPS_1)}</GroupsColumn>
              <GroupsColumn>{renderGroup(GROUPS_2)}</GroupsColumn>
              <GroupsColumn>{renderGroup(GROUPS_3)}</GroupsColumn>
              <GroupsColumn>{renderGroup(GROUPS_4)}</GroupsColumn>
            </GroupsContainer>
            {/* <GroupsContainer>{renderGroup(ALL_GROUPS)}</GroupsContainer> */}
            {/* <div>{renderGroup(RIGHT_COLUMN_GROUPS)}</div>
          <div style={{ gridColumn: "span 2" }}>
            {renderGroup(BOTTOM_GROUPS)}
          </div> */}
          </>
        )}
      </ScrollableContent>
    </Container>
  );
};

export default DealFieldsEditor;
