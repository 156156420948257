import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { cloneDeep, get, isNil, last, merge, set, uniq } from "lodash";

import Modal from "components/ui/Modal";
import { getParsedResults, patchParsedResult } from "api/services/dealService";
import { Gap } from "components/Layout";
import { getObjectPaths, safeFormat, strToColor } from "utils/common";
import DealFieldsEditor from "components/DealFieldsEditor";
import SpanWithHoverTooltip from "components/SpanWithHoverTooltip";
import { CrossIcon, PencilIcon } from "components/ui/Icons";
import { Fragment } from "react";
import { Search } from "@material-ui/icons";
import SearchableSelect from "components/SearchableSelect";
import { postFormatValue } from "api/backend/dealEndpoints";

// segment { text, meta, fieldName }
export const getTextSegments = (
  text,
  fields,
  onMetaFilter = meta => !meta?.inSubject,
  inSubject = false
) => {
  const paths = getObjectPaths(fields)
    ?.filter(path => !path.includes(".meta"))
    ?.map(path => path.replace(".value", ""));

  const metas = [];
  paths?.forEach(path => {
    const meta = get(fields, `${path}.meta`);
    if (meta) {
      metas.push({ ...meta, fieldName: path });
    }
  });

  const sel = window.getSelection();
  if (sel?.toString()) {
    metas.push({
      startInd: Math.min(sel?.anchorOffset, sel?.focusOffset),
      endInd: Math.max(sel?.anchorOffset, sel?.focusOffset),
      inSubject,
      fieldValue: sel?.toString(),
      fieldName: "TBD",
    });
  }

  const segments = [];
  let currentSegment = { text: "", meta: undefined };
  let charIndex = 0;

  while (charIndex < text?.length) {
    const metaAtIndex = metas
      ?.filter(onMetaFilter)
      ?.find(m => charIndex >= m?.startInd && charIndex < m?.endInd);

    if (JSON.stringify(metaAtIndex) !== JSON.stringify(currentSegment.meta)) {
      segments.push(currentSegment);
      currentSegment = { text: "", meta: metaAtIndex };
    }

    currentSegment.text += text[charIndex];
    charIndex++;
  }

  segments.push(currentSegment);

  return segments;
};

export const getSelectOptionsFromSchema = schema => {
  const options = [];

  schema?.fields?.forEach(field => {
    if (!field?._meta?.groupName) {
      return;
    }

    if (field?.type === "record") {
      field?.fields?.forEach(subField => {
        options?.push({
          name: `${field?.name}.${subField?.name}`,
          value: `${field?.name}.${subField?.name}`,
          group: field?._meta?.groupName,
        });
      });
      return;
    }

    options?.push({
      name: field?.name,
      value: field?.name,
      group: field?._meta?.groupName,
    });
  });

  return options;
};

const ModalContent = styled.div`
  padding: 10px;
  white-space: pre-wrap;
  width: 95vw;
  height: 90vh;
  line-height: 1.2;
  overflow: hidden;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  outline: none;

  display: grid;
  grid-template-columns: 1fr 500px;
`;

const EmailHtml = styled.div`
  position: relative;
  height: 66vh;
  overflow: auto;
  border: 1px solid #ccc;
`;

const StyledSelect = styled.select`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Inter";

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  fill: white;
  height: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const LabelTip = styled.div`
  padding: 2px 4px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
`;

const StyledPencilIcon = styled(PencilIcon)`
  cursor: pointer;
  height: 12px;
  :hover {
    background-color: #ccc;
  }
`;

const getExtractedFieldsFromParsedResults = (
  extractedFields,
  parsedResults,
  newPath
) => {
  if (extractedFields?.[newPath]?.meta?.manuallyEntered) {
    return extractedFields;
  }

  const newExtractedFields = cloneDeep(extractedFields);
  const allParsedResults = parsedResults?.map(parsedResult => {
    const pathValue = get(parsedResult?.extractedFields, newPath);
    const partialResult = {};
    set(partialResult, newPath, pathValue);
    return partialResult;
  });
  const mergedParsedResult = merge(...allParsedResults);

  const mergedExtractedFields = merge(newExtractedFields, mergedParsedResult);
  return mergedExtractedFields;
};

const DealDetailsModalTrigger = ({
  record,
  onClickSave = newFields => {},
  schema = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState("");
  const [parsedResults, setParsedResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectionInSubject, setIsSelectionInSubject] = useState(false);

  const [extractedFields, setExtractedFields] = useState({});
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [isMouseDown, setIsMouseDown] = useState(false);

  const emailBodyRef = useRef(null);

  const [parsedResultsSegments, setParsedResultsSegments] = useState([]);
  const [subjectSegments, setSubjectSegments] = useState([]);

  const selectedParsedResult =
    parsedResults?.find(
      parsedResult => parsedResult?._id === selectedEmailId
    ) || {};

  // useEffect(() => {
  //   if (isOpen) {
  //     console.log({ record });
  //   }
  // }, [isOpen]);

  useEffect(() => {
    const { text, subject } = selectedParsedResult || {};
    let newParsedResultsSegments = getTextSegments(
      text,
      selectedParsedResult?.extractedFields
    );
    let newSubjectSegments = getTextSegments(
      subject,
      selectedParsedResult?.extractedFields,
      meta => meta?.inSubject,
      true
    );

    if (isSelectionInSubject) {
      newParsedResultsSegments = parsedResultsSegments?.filter(
        segment => segment?.meta?.fieldName !== "TBD"
      );
    } else {
      newSubjectSegments = newSubjectSegments?.filter(
        segment => segment?.meta?.fieldName !== "TBD"
      );
    }

    setParsedResultsSegments(newParsedResultsSegments);
    setSubjectSegments(newSubjectSegments);
  }, [selectedText, JSON.stringify(selectedParsedResult)]);

  useEffect(() => {
    setExtractedFields(record?.extractedFields);
  }, [JSON.stringify(record?.extractedFields)]);

  useEffect(() => {
    doPopulateParsedResults(record?.parsedResultIds);
  }, [JSON.stringify(record?.emailIds)]);

  const doPopulateParsedResults = async parsedResultIds => {
    const { data } = await getParsedResults({ parsedResultIds });
    setParsedResults(data);
    setSelectedEmailId(last(data)?._id);
  };

  const onSelectPath = async newPath => {
    setIsLoading(true);
    setSelectedPath(newPath);

    if (newPath === "-" || newPath === "") {
      setIsLoading(false);
      return;
    }

    let tbdSegment = parsedResultsSegments?.find(
      segment => segment?.meta?.fieldName === "TBD"
    );
    if (isSelectionInSubject) {
      tbdSegment = subjectSegments?.find(
        segment => segment?.meta?.fieldName === "TBD"
      );
    }

    let { startInd, endInd, fieldValue } = tbdSegment?.meta || {};
    if (!fieldValue) {
      setIsLoading(false);
      return;
    }

    // TODO use on all fields
    if (newPath === "Active Bookrunners") {
      const { data } = await postFormatValue(
        {},
        {
          dealId: record?._id,
          text: fieldValue,
          field: newPath,
        }
      );
      fieldValue = data?.value || fieldValue;
    }

    const newExtractedFieldsParsedResult = cloneDeep(
      selectedParsedResult?.extractedFields
    );
    set(newExtractedFieldsParsedResult, `${newPath}.meta`, {
      startInd,
      endInd,
      emailId: selectedEmailId,
      inSubject: isSelectionInSubject,
    });
    set(newExtractedFieldsParsedResult, `${newPath}.value`, fieldValue);

    const newParsedResults = parsedResults.map(parsedResult => {
      if (parsedResult?._id === selectedEmailId) {
        return {
          ...parsedResult,
          extractedFields: newExtractedFieldsParsedResult,
        };
      }
      return parsedResult;
    });

    await patchParsedResult(
      selectedEmailId,
      {},
      { extractedFields: newExtractedFieldsParsedResult }
    );

    setParsedResults(newParsedResults);

    const newExtractedFields = getExtractedFieldsFromParsedResults(
      extractedFields,
      newParsedResults,
      newPath
    );
    setExtractedFields(newExtractedFields);
    setSelectedPath("");

    setIsLoading(false);
  };

  const doRemoveMeta = async fieldName => {
    setIsLoading(true);
    const newExtractedFieldsParsedResult = cloneDeep(
      selectedParsedResult?.extractedFields
    );
    set(newExtractedFieldsParsedResult, `${fieldName}.meta`, {});
    set(newExtractedFieldsParsedResult, `${fieldName}.value`, null);

    const newParsedResults = parsedResults.map(parsedResult => {
      if (parsedResult?._id === selectedEmailId) {
        return {
          ...parsedResult,
          extractedFields: newExtractedFieldsParsedResult,
        };
      }
      return parsedResult;
    });

    await patchParsedResult(
      selectedEmailId,
      {},
      { extractedFields: newExtractedFieldsParsedResult }
    );

    setParsedResults(newParsedResults);

    const newExtractedFields = getExtractedFieldsFromParsedResults(
      extractedFields,
      newParsedResults,
      fieldName
    );
    setExtractedFields(newExtractedFields);

    setIsLoading(false);
  };

  // let parsedResultsSegments = getTextSegments(
  //   selectedParsedResult?.text,
  //   selectedParsedResult?.extractedFields
  // );

  // let subjectSegments = getTextSegments(
  //   selectedParsedResult?.subject,
  //   selectedParsedResult?.extractedFields,
  //   meta => meta?.inSubject,
  //   true
  // );

  // if (isSelectionInSubject) {
  //   parsedResultsSegments = parsedResultsSegments?.filter(
  //     segment => segment?.meta?.fieldName !== "TBD"
  //   );
  // } else {
  //   subjectSegments = subjectSegments?.filter(
  //     segment => segment?.meta?.fieldName !== "TBD"
  //   );
  // }

  const selectOptions = getSelectOptionsFromSchema(schema);

  return (
    <>
      <button
        style={{
          // position: "absolute",
          // right: "8px",
          // top: "8px",
          backgroundColor: "transparent",
          border: "none",
          padding: "0",
        }}
        onClick={() => setIsOpen(true)}
      >
        <StyledPencilIcon />
      </button>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <div
            style={{
              paddingRight: "10px",
              // height: "600px",
              overflow: "auto",
              borderBottom: "1px solid #ccc",

              display: "grid",
              gridTemplateRows: "auto auto",
            }}
          >
            <DealFieldsEditor
              dealId={record?._id}
              fields={extractedFields}
              schema={schema}
              onChange={newFields => setExtractedFields(newFields)}
            />
          </div>

          <div style={{ paddingLeft: "0px", display: "grid" }}>
            <StyledSelect
              style={{ width: "100%" }}
              size="4"
              value={selectedEmailId}
              onChange={e => setSelectedEmailId(e.target.value)}
            >
              {record?.parsedResultIds?.map(id => {
                const parsedResult = parsedResults?.find(
                  parsedResult => parsedResult?._id === id
                );
                const { date, subject, from } = parsedResult || {};

                return (
                  <option value={id} key={id} style={{ padding: "2px" }}>
                    {subject}
                  </option>
                );
              })}
            </StyledSelect>

            <SearchableSelect
              placeholder={
                selectedText ? "-- Choose label --" : "-- Select text --"
              }
              style={{
                width: "300px",
                zIndex: 1,
                justifySelf: "end",
                backgroundColor: "white",
                opacity: selectedText ? 1 : 0.5,
                pointerEvents: selectedText ? "all" : "none",
              }}
              value={selectedPath}
              onChange={e => onSelectPath(e.target.value)}
              options={selectOptions}
            />
            <div
              style={{
                height: "50px",
                fontWeight: 600,
                position: "relative",
                border: "1px solid #ccc",
              }}
              onMouseDown={e => {
                if (e.detail > 1) {
                  e.preventDefault();
                }
                setIsMouseDown(true);
              }}
              onMouseUp={e => {
                if (window.getSelection()?.toString() && selectedPath) {
                  onSelectPath(selectedPath);
                }

                setIsSelectionInSubject(true);
                setSelectedText(window.getSelection()?.toString());
                setIsMouseDown(false);
              }}
            >
              {selectedParsedResult?.subject}
              <div
                style={{
                  position: "absolute",
                  pointerEvents: isMouseDown ? "none" : "all",
                  top: 0,
                  left: 0,
                  color: "transparent",
                  width: "100%",
                }}
              >
                {subjectSegments?.map((segment, i) => (
                  <SpanWithHoverTooltip
                    key={i}
                    style={{
                      outline: "1px solid transparent",
                      backgroundColor: !isNil(segment?.meta?.startInd)
                        ? `${strToColor(segment?.meta?.fieldName)}55`
                        : "transparent",
                    }}
                    isTooltipDisabled={isNil(segment?.meta?.fieldName)}
                    tooltip={
                      <LabelTip>
                        <StyledCrossIcon
                          onClick={() => doRemoveMeta(segment?.meta?.fieldName)}
                        />
                        {segment?.meta?.fieldName}
                      </LabelTip>
                    }
                  >
                    {segment?.text}
                  </SpanWithHoverTooltip>
                ))}
              </div>
            </div>

            <EmailHtml
              ref={emailBodyRef}
              onMouseDown={e => {
                if (e.detail > 1) {
                  e.preventDefault();
                }
                setIsMouseDown(true);
              }}
              onMouseUp={() => {
                if (window.getSelection()?.toString() && selectedPath) {
                  onSelectPath(selectedPath);
                }

                setIsSelectionInSubject(false);
                setSelectedText(window.getSelection()?.toString());
                setIsMouseDown(false);
              }}
            >
              {selectedParsedResult?.text}
              <div
                style={{
                  position: "absolute",
                  pointerEvents: isMouseDown ? "none" : "all",
                  top: 0,
                  left: 0,
                  color: "transparent",
                  width: "100%",
                }}
              >
                {parsedResultsSegments?.map((segment, i) => (
                  <SpanWithHoverTooltip
                    key={i}
                    style={{
                      outline: "1px solid transparent",
                      backgroundColor: !isNil(segment?.meta?.startInd)
                        ? `${strToColor(segment?.meta?.fieldName)}55`
                        : "transparent",
                    }}
                    isTooltipDisabled={
                      isNil(segment?.meta?.fieldName) ||
                      segment?.meta?.fieldName === "TBD"
                    }
                    tooltip={
                      <LabelTip>
                        <StyledCrossIcon
                          onClick={() => doRemoveMeta(segment?.meta?.fieldName)}
                        />
                        {segment?.meta?.fieldName}
                      </LabelTip>
                    }
                  >
                    {segment?.text}
                  </SpanWithHoverTooltip>
                ))}
              </div>
            </EmailHtml>
          </div>
        </ModalContent>

        <div style={{ gridColumn: "span 2", padding: "8px" }}>
          <button
            onClick={async () => {
              setIsLoading(true);
              await onClickSave({ extractedFields });
              setIsLoading(false);
              setIsOpen(false);
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DealDetailsModalTrigger;
