import styled from "styled-components";
import { useState, useEffect } from "react";

import { getDeals, getEmails } from "api/services/dealService";
import {
  ArchiveIcon,
  DashboardIcon,
  EmailIcon,
  GearIcon,
  LiquidnetLogo,
  LogoInner,
  ParserLogo,
  TopLeftTriangle,
  WaveLogo,
} from "components/ui/Icons";
import NavHeader from "components/widgets/NavHeader";
import { Link } from "react-router-dom";
import { isArchivedDeal } from "utils/common";

const Container = styled.div`
  position: fixed;
  left: 0;

  display: grid;
  align-content: start;
  justify-items: start;
  align-items: start;
  padding-bottom: 0px;
  background-color: #2f3135;
  color: #6f7880;
  height: 100vh;
  /* position: sticky;
  top: 0; */
  box-shadow: ${props => props.theme.shadow};
`;

const NavLink = styled(Link)`
  position: relative;
  width: 60px;
  height: 60px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 8px;
  text-decoration: none;
  color: ${props => (props.isSelected ? "#0ECBB4" : "#6f7880")};
  font-size: 9px;
  border-left: 4px solid
    ${props => (props.isSelected ? props.theme.color.primary : "transparent")};
  background-color: ${props => (props.isSelected ? "#181A1F" : "transparent")};

  svg {
    height: 22px;
    fill: ${props => (props.isSelected ? "#0ECBB4" : "#6f7880")};
  }

  :hover {
    color: #0ecbb4;
    svg {
      fill: #0ecbb4;
    }
  }
`;

const LogoContainer = styled.div`
  padding: 8px;
  padding-top: 14px;
  display: grid;
  justify-items: center;
  align-content: center;
  gap: 8px;
  width: 100%;
  height: 52px;
`;

const StyledTopLeftTriangle = styled(TopLeftTriangle)`
  position: fixed;
  height: 12px;
  top: 0;
  left: -4px;
`;

const DotIndicator = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${props => props.theme.color.in_progress};
  font-size: 9px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;

const SideNav = ({ style = {} }) => {
  const [emailRecords, setEmailRecords] = useState([]);
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    doPopulateEmails();

    const intervalId = setInterval(() => {
      doPopulateEmails();
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const doPopulateEmails = async () => {
    const { data } = await getEmails();
    setEmailRecords(
      data?.filter(record => record?.parsingStatus !== "DONE") || []
    );
  };

  const unresolvedCount =
    emailRecords?.filter(
      record => record?.parsingStatus === "DONE_UNRESOLVED_DEAL"
    )?.length || 0;

  return (
    <Container style={style}>
      <LogoContainer>
        <StyledTopLeftTriangle />
        <WaveLogo />
        <LiquidnetLogo style={{ fill: "white" }} />
      </LogoContainer>
      <NavLink
        to="/"
        isSelected={
          window?.location?.pathname === "/" ||
          window?.location?.pathname?.includes("/deals/")
        }
      >
        <DashboardIcon />
        Dashboard
      </NavLink>
      <NavLink
        to="/parser"
        isSelected={window?.location?.pathname === "/parser"}
      >
        <ParserLogo />
        Parser
      </NavLink>
      <NavLink
        to="/archive"
        isSelected={window?.location?.pathname === "/archive"}
      >
        <ArchiveIcon />
        Archive
      </NavLink>
      {/* <NavLink to="/all" isSelected={window?.location?.pathname === "/all"}>
        All deals
      </NavLink> */}
      <NavLink
        to="/missing"
        isSelected={
          window?.location?.pathname === "/missing" ||
          window?.location?.pathname?.includes("/emails/")
        }
      >
        {unresolvedCount > 0 && <DotIndicator>{unresolvedCount}</DotIndicator>}
        <EmailIcon style={{ width: "24px" }} />
        Emails
      </NavLink>
      <NavLink to="/chat" isSelected={window?.location?.pathname === "/chat"}>
        <LogoInner />
        Analytics
      </NavLink>
      <NavLink
        to="/settings"
        isSelected={window?.location?.pathname === "/settings"}
      >
        <GearIcon />
        Settings
      </NavLink>
      <NavHeader />
    </Container>
  );
};

export default SideNav;
