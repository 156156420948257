import jwt_decode from "jwt-decode";

import * as backend from "api/backend/authenticationServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getCookieByName } from "utils/common";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";

export const getAccessToken = async (username, password) => {
  const { data, error } = await backend.getLoginTokens(username, password);
  return { data, error: getHandlingFromError(error) };
};

export const getLoggedInUserName = () => {
  try {
    const accessToken = getLoginTokenFromClientStorage();
    const { sub } = jwt_decode(accessToken);
    return sub;
  } catch {
    return "";
  }
};

export const isUserLoggedIn = () => {
  return !!getLoggedInUserName();
};

export const getPlatformLoginToken = async (username, password) => {
  const { data, error } = await backend.getLoginTokens(username, password);

  if (error) {
    const errorHandlingDetails = getHandlingFromError(error);
    return { data, error: errorHandlingDetails };
  }

  return { data, error };
};
