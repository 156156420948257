import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { getRecommendedNlc } from "api/services/dealService";

const SuggestionsContainer = styled.div`
  position: fixed;
  box-shadow: ${props => props.theme.shadow};
  background-color: #181a1f;
  padding: 12px 8px;
  height: 62px;
  overflow-y: hidden;
  overflow-x: auto;

  display: grid;
  grid-auto-flow: column;
  grid-column: 1fr;
  gap: 8px;
  border: 1px solid ${props => props.theme.color.primary};
  border-bottom: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const Suggestion = styled.div`
  padding: 4px 8px;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  :hover {
    background-color: #242930;
  }
  padding: 8px;
  border: 1px solid #4c5562;
  border-radius: 2px;
  line-height: 1.2;
  display: flex;
  align-items: center;
`;

const SUGGESTIONS = [
  "grouping by Tranche Status sum Book Size",
  "grouping by Tranche Status sum Tranche Size",
];

const InputWithSuggestions = ({
  className,
  value,
  onChange = e => {},
  placeholder,
  disabled,
  onKeyDown = e => {},
  onClickSuggestion = text => {},
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isFocussed, setIsFocussed] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (disabled) {
      setIsFocussed(false);
    }
  }, [disabled]);

  useEffect(() => {
    doPopulateSuggestions();
  }, []);

  const doPopulateSuggestions = async () => {
    const { data } = await getRecommendedNlc();
    setSuggestions(data || []);
  };

  const rect = ref.current?.getBoundingClientRect();

  return (
    <>
      <input
        className={className}
        ref={ref}
        value={value}
        onChange={onChange}
        onKeyDown={e => {
          if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            e.preventDefault();
          }

          onKeyDown(e);
        }}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => setIsFocussed(true)}
        onBlur={() => setIsFocussed(false)}
      />
      <SuggestionsContainer
        style={{
          display: isFocussed ? "flex" : "none",
          top: rect?.top - 62,
          left: rect?.left,
          width: rect?.width,
        }}
      >
        {suggestions.map(suggestion => {
          return (
            <Suggestion
              key={suggestion}
              onMouseDown={e => {
                e.preventDefault();
                e.stopPropagation();
                onClickSuggestion(suggestion);
              }}
            >
              {suggestion}
            </Suggestion>
          );
        })}
      </SuggestionsContainer>
    </>
  );
};

export default InputWithSuggestions;
